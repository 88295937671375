import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import firebase from 'firebase';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { Observable } from 'rxjs/internal/Observable';
import { LocalStorageService, StorageKey } from 'src/app/local-storage.service';
import { PurchasesService } from './purchases.service';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  constructor(private storage: LocalStorageService, private purchaseService: PurchasesService) {
    firebase.auth().onAuthStateChanged(user => {
      this.onUserAuth(user);
      console.log('onAuthStateChanged', user);
    });
    let userId = this.getUserId();
    if (userId) {
      this.listenToDoc(userId);
    }
  }

  isLoggedIn = new BehaviorSubject<boolean>(this.storage.getItem(StorageKey.USER_ID) != undefined)
  isLoggedIn$ = this.isLoggedIn.asObservable();

  userData;

  user = new BehaviorSubject<any>(undefined);
  user$ = this.user.asObservable();

  tours = new BehaviorSubject<any>([]);
  tours$ = this.tours.asObservable();

  userId;

  listenToDoc(userId) {
    firebase.firestore().collection('users').doc(userId).onSnapshot(doc => {
      console.log('User data:', doc.data());
      if (doc.exists) {
        this.onUserData(doc.data());
      }
    });
  }

  async onUserAuth(user) {
    this.isLoggedIn.next(user != null);
    if (user) {
      await this.purchaseService.initRevenueCat(user.uid);
      this.storage.setItem(StorageKey.USER_ID, user.uid);
      this.storage.setItem(StorageKey.USER_EMAIL, user.email);
      this.listenToDoc(user.uid);
    } else {
      this.storage.removeItem(StorageKey.USER_ID);
    }
  }

  onUserData(user) {
    this.userData = user
    this.user.next(user)
    this.getUsersTours(user)
  }

  private getUsersTours(user) {
    let tourIds = user.purchasedTourIds
    if (tourIds == undefined || tourIds.length == 0) return
    this.tours.next([])
    let tours = []
    firebase.firestore().collection('guided-tours').where('id', 'in', tourIds).get().then(querySnapshot => {
      querySnapshot.forEach(doc => {
        let tour = doc.data()
        tour.purchased = true
        tours.push(tour)
      })
    })
    this.tours.next(tours)
    console.log("this.tours", this.tours)
  }

  getUserId() {
    let id = this.userId == undefined ? this.storage.getItem(StorageKey.USER_ID) : this.userId;
    console.log('getUserId', id);
    return id;
  }

  getUserEmail() {
    let email = this.storage.getItem(StorageKey.USER_EMAIL) || firebase.auth().currentUser.email
    console.log('getUserEmail', email)
    return email
  }

  async onLogin(firebaseUser: firebase.User) {
    console.log('onLogin', firebaseUser);
    this.userId = firebaseUser.uid;
    this.storage.setItem(StorageKey.USER_ID, this.userId);
    let docRef = firebase.firestore().collection('users').doc(firebaseUser.uid);
    let doc = await docRef.get()
    if (doc.exists) {
      this.onUserData(doc.data());
      console.log('Existing user!');
    } else {
      let data = {
        id: this.userId,
        created: firebase.firestore.FieldValue.serverTimestamp(),
        email: firebaseUser.email,
        name: firebaseUser.displayName,
        photo: firebaseUser.photoURL,
      };
      await docRef.set(data);
      this.onUserData(data);
      await this.assignPartnerId(this.userId);
      console.log('New user!', this.userData);
    }
    await this.onUserAuth(firebaseUser);
  }

  logout() {
    firebase.auth().signOut().then(() => {
      this.userData = undefined;
      this.user.next(undefined);
    });
  }

  setPartnerId(partnerId) {
    this.storage.setItem(StorageKey.PARTNER_ID, partnerId)
    console.log('setPartnerId', partnerId)
    let userId = this.getUserId()
    if (userId) {
      this.assignPartnerId(userId)
    }
  }

  async assignPartnerId(userId) {
    let partnerId = this.storage.getItem(StorageKey.PARTNER_ID);
    if (partnerId) {
      let result = await firebase.functions().httpsCallable('linkUserWithPartner')({
        partnerId: partnerId,
        userId: userId
      });
      console.log('assignPartnerId', result);
    }
  }

  hasPurchasedTour(tourId) {
    return this.userData != undefined && this.userData.purchasedTourIds != undefined && this.userData.purchasedTourIds.includes(tourId)
  }

  setCreateTourCityId(cityId) {
    firebase.firestore().collection('users').doc(this.getUserId()).update({
      recentWebCreateTour: {
        cityId: cityId,
        date: firebase.firestore.FieldValue.serverTimestamp()
      }
    })
  }
}

@Injectable()
export class UserAuthGuard implements CanActivate {
  constructor(
    private _authService: UserService,
    private router: Router
  ) { }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    if (this._authService.isLoggedIn.value) {
      return true;
    } else {
      this.router.navigate(['']); // Redirect to login if not authenticated
      return false;
    }
  }
}