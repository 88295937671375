import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { langs } from '../../../utils/langs';
import stringsJson from '../../../../assets/strings/strings.json';
import { stringFormat } from '../../../utils/utils';
import { PurchasesService } from '../purchases.service';
import { UserService } from '../user.service';

@Component({
  selector: 'app-tour-preview',
  templateUrl: './tour-preview.component.html',
  styleUrls: ['./tour-preview.component.css', '../../../app.component.css'],
})
export class TourPreviewComponent implements OnInit {
  @Input() tour;
  tourTitle;
  playingState = NOT_PLAYING;
  audio = new Audio();
  strings;
  language;
  ratingAvg;
  localPrice;
  hasPurchasedTour = false;
  isPotentialTour = false

  constructor(private route: ActivatedRoute, private purchasesService: PurchasesService, private userService: UserService) { }

  ngOnInit() {
    console.log('preview tour', this.tour);
    if (this.tour == undefined) {
      return;
    }
    this.language = window.location.hostname.split('.')[0];
    if (this.language == undefined || this.language.length > 2) this.language = 'en';
    this.strings = JSON.parse(JSON.stringify(stringsJson))['default'][this.language];

    if (this.tour.titleTranslations != undefined) {
      let title = this.tour.titleTranslations[this.language];
      this.tourTitle = title != undefined ? title : this.tour.title;
    } else {
      this.tourTitle = this.tour.title;
    }
    if (this.tour.ratings != undefined && this.tour.ratings.ratingCount > 0) {
      this.tour.ratings.ratingAvg = this.tour.ratings.ratingSum / this.tour.ratings.ratingCount;
    }
    this.purchasesService.getLocalPrice(this.tour).then(price => {
      this.localPrice = price;
    })
    this.hasPurchasedTour = this.userService.hasPurchasedTour(this.tour.id);
    this.isPotentialTour = this.tour.id.startsWith("p-")
  }

  getLanguage(lang) {
    langs[lang].nativeName;
  }

  async playAudio() {
    if (this.playingState == PLAYING) {
      this.audio.pause();
      this.playingState = NOT_PLAYING;
    } else if (this.playingState == NOT_PLAYING) {
      this.playingState = LOADING;
      this.audio.src = this.tour.landmarks[0].audioUrl;
      this.audio.load();
      await this.audio.play();
      this.playingState = PLAYING;
      this.audio.addEventListener('ended', () => {
        this.playingState = NOT_PLAYING;
      });
    }
  }
}

const NOT_PLAYING = 0;
const LOADING = 1;
const PLAYING = 2;
