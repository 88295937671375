import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { LngLatBounds } from 'mapbox-gl';

@Component({
  selector: 'app-landmark-map',
  templateUrl: './landmark-map.component.html',
  styleUrls: ['./landmark-map.component.css', '../../../app.component.css'],
})
export class LandmarkMapComponent implements OnInit {
  @Input() landmarks;
  features
  bounds
  center

  @Output() onLandmarkClicked = new EventEmitter<any>();


  constructor() { }

  onLandmarkClick(landmark) {
    console.log('landmark', landmark)
    this.onLandmarkClicked.emit(landmark)
  }

  ngOnChanges() {
    if (this.landmarks == undefined || this.landmarks.length == 0) {
      return
    }

    this.features = this.landmarks.sort((a, b) => Number(Boolean(a.thumbnail)) - Number(Boolean(b.thumbnail)))
      .map(landmark => ({
        type: 'Feature',
        properties: {
          landmark: landmark,
        },
        geometry: {
          type: 'Point',
          coordinates: [landmark.latLng[1], landmark.latLng[0]],
        },
      }))

    console.log('this.features', this.features)

    this.setBounds()
  }

  setBounds() {
    this.bounds = this.landmarks.reduce(
      (bounds, coord) => {
        return bounds.extend([coord.latLng[1], coord.latLng[0]]);
      },
      new LngLatBounds(
        [this.landmarks[0].latLng[1], this.landmarks[0].latLng[0]],
        [this.landmarks[0].latLng[1], this.landmarks[0].latLng[0]]
      )
    );

    const sw = this.bounds.getSouthWest();
    const ne = this.bounds.getNorthEast();

    const centerLng = (sw.lng + ne.lng) / 2;
    const centerLat = (sw.lat + ne.lat) / 2;

    const newSwLng = centerLng - (centerLng - sw.lng) * 1.5;
    const newSwLat = centerLat - (centerLat - sw.lat) * 1.5;
    const newNeLng = centerLng + (ne.lng - centerLng) * 1.5;
    const newNeLat = centerLat + (ne.lat - centerLat) * 1.5;

    this.bounds = new LngLatBounds(
      [newSwLng, newSwLat],
      [newNeLng, newNeLat]
    );

    var totalLat = 0;
    var totalLng = 0;

    this.landmarks.forEach(landmark => {
      totalLat += landmark.latLng[0];
      totalLng += landmark.latLng[1];
    });

    const avgLat = totalLat / this.landmarks.length;
    const avgLng = totalLng / this.landmarks.length;

    this.center = [avgLng, avgLat];
    console.log('this.center', this.center)
  }

  ngOnInit() {

  }
}