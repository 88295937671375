import { Component, Input, OnInit } from '@angular/core';
import { PosterContent } from '../PosterContent';

@Component({
  selector: 'app-partner-poster-2',
  templateUrl: './partner-poster-2.component.html',
  styleUrls: ['./partner-poster-2.component.css', '../../../../../app.component.css'],
})
export class PartnerPosterComponent2 implements OnInit {
  constructor() { }

  @Input() posterContent: PosterContent;

  ngOnInit() {
    console.log("posterContent", this.posterContent)
  }

}
