import { Component, OnInit } from '@angular/core';
import { AdminAuthGuard, AdminAuthService } from '../admin-auth-service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-admin-login',
  templateUrl: './admin-login.component.html',
  styleUrls: ['./admin-login.component.css'],
})
export class AdminLoginComponent implements OnInit {
  adminCode = 'alltours4life';
  enteredCode = '';
  returnUrl;

  constructor(
    private authGuard: AdminAuthGuard,
    private router: Router,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'];
  }

  loginUser() {
    if (this.adminCode == this.enteredCode) {
      console.log('login success');
      localStorage.setItem('adminToken', 'secretAdminToken');
      localStorage.setItem('is_admin', 'true');
      console.log('running after timeout');
      if (this.returnUrl != undefined) {
        this.router.navigateByUrl(this.returnUrl);
      } else {
        this.router.navigate(['/admin']);
      }
      setTimeout(() => {
        window.location.reload();
      }, 500);
    }
  }
}
