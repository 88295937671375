import firebase from 'firebase';

export async function runFunction(functionName: AllToursFunction, request = undefined) {
  try {
    let result = await firebase.functions().httpsCallable(functionName)(request)
    console.log('runFunction', functionName, request, result)
    return result.data
  } catch (error) {
    console.error(error)
  }
  return undefined
}

export enum AllToursFunction {
  createRedemptionCodeV2 = 'createRedemptionCodeV2',
  createUnlimitedRedemptionCode = 'createUnlimitedRedemptionCode',
  linkUserWithPartner = 'linkUserWithPartner',
  getPartnerStripeAccount = 'getPartnerStripeAccount',
  getPartnerStripeOnboardingLink = 'getPartnerStripeOnboardingLink',
  getPartnerStripeDashboardLink = 'getPartnerStripeDashboardLink',
  searchAll = 'searchAll',
}
