import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit, ViewEncapsulation, Inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import citiesJson from '../../../../assets/json/mini_cities.json';
import topCitiesJson from '../../../../assets/json/top_tourist_cities.json';
import firebase from 'firebase';
import { EventEmitter, Output } from '@angular/core';
import { DomSanitizer, SafeHtml, Title } from '@angular/platform-browser';
import { MatDialog } from '@angular/material/dialog';
import stringsJson from '../../../../assets/strings/strings.json';
import { setSeoText, stringFormat } from '../../../utils/utils';
import exampleTour from '../../../../assets/json/example_tour.json';
import { fadeInUpAnimation, fadeAnimation } from 'src/app/utils/animations';
import { UserService } from '../user.service';

const db = firebase.firestore();

@Component({
  selector: 'app-city',
  templateUrl: './city.component.html',
  styleUrls: ['./city.component.css', '../../../app.component.css'],
  encapsulation: ViewEncapsulation.None,
  animations: [fadeInUpAnimation, fadeAnimation]
})
export class CityComponent implements OnInit {
  jsonLD: SafeHtml;

  constructor(
    @Inject(MatDialog) public dialog: MatDialog,
    private route: ActivatedRoute,
    private sanitizer: DomSanitizer,
    private router: Router,
    private userService: UserService,
  ) { }

  cities = citiesJson.cities;
  countryName;
  city;
  localisedCityName;
  article;
  strings;
  title;
  subTitle;
  defaultArticle;
  language;
  tours = [];
  showCta1 = false;
  loadingTours = true;
  loadingCity = true;
  cityLandmarks = [];
  potentialTours = [];

  ngOnInit() {
    this.language = window.location.hostname.split(".")[0]
    if (this.language == undefined || this.language.length > 2) this.language = "en"
    this.strings = JSON.parse(JSON.stringify(stringsJson))["default"][this.language]
    this.countryName = this.route.snapshot.paramMap.get("countryName")
    this.getCity()
    if (this.city != undefined) {
      this.title = stringFormat(this.strings.city_audio_tours_in_language, this.city.names[this.language] ?? this.city.name)
      this.subTitle = this.strings.explore_at_own_pace
      this.defaultArticle = this.strings.default_article
      this.article = this.defaultArticle
      this.setSeoText()
      this.getArticle()
      this.getTours()
    }
  }

  async getCity() {
    let cityName = this.route.snapshot.paramMap.get("cityName").toLowerCase().replace("%20", " ")
    console.log("cityName", cityName)
    this.city = this.cities.find((c) =>
      ((c.names != undefined && c.names[this.language] != undefined && c.names[this.language].toLowerCase() == cityName) || c.name.toLowerCase() == cityName)
      && c.country.toLowerCase() == this.countryName.toLowerCase()
    )
    if (this.city == undefined) {
      this.city = this.cities.find((c) =>
        (c.names[this.language].toLowerCase() == cityName || c.name.toLowerCase() == cityName)
      )
    }
    this.localisedCityName = this.city.names != undefined ? (this.city.names[this.language] ? this.city.names[this.language] : this.city.name) : this.city.name
    let cityDocData = (await db.collection("cities").doc(this.city.id.toString()).get()).data()
    if (cityDocData != undefined) {
      this.cityLandmarks = cityDocData.allLandmarks
      this.potentialTours = cityDocData.potentialTours
      this.city.image = cityDocData.image
      console.log('cityDocData', cityDocData)
    }
    this.loadingCity = false
  }

  async getTours() {
    this.loadingTours = true;
    try {
      let [toursResponse, potentialToursResponse] = await Promise.all([
        firebase.firestore().collection('guided-tours')
          .where('city.id', '==', parseInt(this.city.id))
          .where('landmarkCount', '>', 4)
          .limit(10).get(),
        firebase.firestore().collection('potential-tours')
          .where('city.id', '==', parseInt(this.city.id))
          .where('generated', '==', false)
          .limit(3).get()
      ])

      let tours = toursResponse.docs.map(doc => doc.data())
      let potentialTours = potentialToursResponse.docs.map(doc => doc.data())
        .filter(potentialTour => !tours.find(tour => tour.title == potentialTour.title))

      this.tours = [...tours, ...potentialTours].filter(t => t.guide != undefined && t.id != undefined)

      this.tours.sort((a, b) => {
        const ratingA = a.ratings?.ratingSum ?? 0;
        const ratingB = b.ratings?.ratingSum ?? 0;
        return ratingB - ratingA
      });

    } catch (e) {
      console.error(e)
    }
    this.loadingTours = false;
  }

  setSeoText() {
    let description = stringFormat(
      this.strings.city_sub_title,
      this.city.names[this.language]
    )
    setSeoText(this.title, description)
  }

  getSafeHTML(value: {}) {
    const json = JSON.stringify(value, null, 2);
    const html = `<script type="application/ld+json">${json}</script>`;
    // Inject to inner html without Angular stripping out content
    return this.sanitizer.bypassSecurityTrustHtml(html);
  }

  async getArticle() {
    var data = null;
    if (topCitiesJson.cities.find(c => c.id == this.city.id) != null) {
      try {
        data = (
          await firebase.functions().httpsCallable('getAllToursArticle', { timeout: 4000 })({
            language: this.language,
            cityId: this.city.id,
          })
        ).data;
      } catch (e) {
        console.error(e);
        data = null;
      }
    }
    if (data == undefined || data == null) {
      this.article = this.defaultArticle;
    } else {
      this.article = data.replace(/<title>[^]*<\/title>/g, '');
    }
  }

  goToApp() {
    if (navigator.userAgent.match(/Mac|iPhone|iPad|iPod/i)) {
      firebase.analytics().logEvent('alltours_cta_click', { type: 'ios' });
      window.open('https://apps.apple.com/app/alltours-audio-tours/id6475402833?platform=iphone');
    } else {
      firebase.analytics().logEvent('alltours_cta_click', { type: 'not-ios' });
      window.open('https://play.google.com/store/apps/details?id=app.alltours');
    }
  }

  onLoginToMakeTour() {
    if (this.userService.isLoggedIn.value) {
      document.getElementById('downloadAppButton').click();
      this.userService.setCreateTourCityId(this.city.id);
    } else {
      document.getElementById('loginButton').click();
    }
  }

  onSuccessfulLogin(event) {
    console.log('onSuccessfulLogin', event);
    document.getElementById('closeLoginButton').click();
    document.getElementById('downloadAppButton').click();
    this.userService.setCreateTourCityId(this.city.id);
  }

  howItWorksCreateTour = [
    {
      title: "Select the landmarks",
      description: "Choose which landmarks you want to include in your tour.",
      icon: "touch_app"
    },
    {
      title: 'Click generate',
      description: "We'll generate the tour for you in less than 2 minutes.",
      icon: "download"
    },
    {
      title: 'Enjoy the tour',
      description: "Listen to the audio guide and explore the city at your own pace.",
      icon: "headset"
    }
  ]
}
