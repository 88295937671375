import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-policy',
  templateUrl: './policy.component.html',
  styleUrls: ['./policy.component.css'],
})
export class PolicyComponent implements OnInit {
  allTours;

  constructor(private route: ActivatedRoute) {}

  ngOnInit() {
    this.allTours = window.location.hostname.includes('alltours');
    console.log('all tours?', this.allTours);
  }
}
