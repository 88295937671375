<div class="container" style="padding-bottom: 256px;">
  <div class="row">
    <div class="col-12" style="display: flex;" *ngIf="partnerService.partner$ | async as partner">
      <div *ngIf="partner.name != undefined" class="topPartnerHeader">Welcome back, {{ partner.name }}</div>
      <div *ngIf="partner.name == undefined" class="topPartnerHeader">Welcome back</div>
      <div style="text-align: right; margin-left: auto; align-content: center;">
        <div *ngIf="partner.tourSales != undefined && partner.tourSales > 0">
          <p class="totalEarningsText">{{partner.tourSales}}</p>
          <p class="secondaryText" style="margin: 0;">Tours sold</p>
        </div>
      </div>
    </div>
  </div>
  <div class="row topCardHolder"
    *ngIf="!printedPosterOnce || (!partnerService.paymentLinkLoading && partnerService.needsPaymentSetup) || !hasClickedDownloadApp">
    <div class="col-12">
      <h3>Get started with AllTours</h3>
    </div>
    <div class="col-6 col-lg-4" *ngIf="!printedPosterOnce && !partnerService.isIndividual"
      style="margin-top: 48px;font-size: 16px;">
      <div>
        <b>Print out your first poster</b><br />
        <a routerLink="/partner/marketing/poster?id=1" style="color: #3b4d00">Print <i
            class="fa fa-arrow-right"></i></a>
      </div>
      <img src="../../../../assets/images/alltours/poster-example.png" routerLink="/partner/marketing/poster?id=1"
        class="getStartedImage" />
    </div>
    <div class="col-6 col-lg-4" *ngIf="!printedPosterOnce && partnerService.isIndividual"
      style="margin-top: 48px;font-size: 16px;">
      <div>
        <b>Download your first marketing asset</b><br />
        <a routerLink="/partner/marketing" style="color: #3b4d00">
          Download <i class="fa fa-arrow-right"></i>
        </a>
      </div>
      <img src="../../../../assets/images/alltours/partnerAssets/example.jpg" routerLink="/partner/marketing"
        class="getStartedImage" />
    </div>
    <div class="col-6 col-lg-4" *ngIf="!partnerService.paymentLinkLoading && partnerService.needsPaymentSetup"
      style="margin-top: 48px;font-size: 16px;">
      <div>
        <b>Setup your payout method</b><br />
        <a href="{{ partnerService.stripeLink}}" style="color: #3b4d00">Setup <i class="fa fa-arrow-right"></i></a>
      </div>
      <a href="{{ partnerService.stripeLink}}" style="color: #3b4d00">
        <img src="../../../../assets/images/alltours/payment_onboarding.png" class="getStartedImage" />
      </a>
    </div>
    <div class="col-6 col-lg-4" *ngIf="!hasClickedDownloadApp" style="margin-top: 48px;font-size: 16px;">
      <div>
        <b>Checkout the AllTours app for yourself</b><br />
        <a (click)="goToAppStore()" target="_blank" style="color: #3b4d00">Download <i
            class="fa fa-arrow-right"></i></a>
      </div>
      <img (click)="goToAppStore()" src="../../../../assets/images/alltours/download_app.png" class="getStartedImage" />
    </div>
  </div>
  <div class="row">
    <div class="col-12 col-md-6">
      <div class="rounded-box" routerLink="/partner/marketing">
        <i class="fa fa-image"></i><br />
        <span class="primaryText">Marketing</span><br />
        <span class="secondaryText">Print posters / Download marketing assets</span>
      </div>
    </div>
    <div class="col-12 col-md-6">
      <div *ngIf="partnerService.paymentLinkLoading" class="rounded-box">
        <i class="fa fa-spinner fa-spin"></i><br />
        <span class="primaryText">Payouts</span><br />
        <span class="secondaryText">Loading...</span>
      </div>
      <a href="{{ partnerService.stripeLink }}"
        *ngIf="!partnerService.paymentLinkLoading && partnerService.needsPaymentSetup" style="text-decoration: 0;">
        <div class="rounded-box" style="border: 1px solid #f00">
          <i style="color: #f00" class="fa fa-usd"></i><br />
          <span class="primaryText" style="color: #f00">Payouts disabled</span><br />
          <span class="secondaryText">Details required. Please add here.</span>
        </div>
      </a>
      <a href="{{ partnerService.stripeLink }}"
        *ngIf="!partnerService.paymentLinkLoading && !partnerService.needsPaymentSetup" style="text-decoration: 0;">
        <div class="rounded-box">
          <i class="fa fa-usd"></i><br />
          <span class="primaryText">Payouts</span><br />
          <span class="secondaryText">Your earnings dashboard</span>
        </div>
      </a>
    </div>
  </div>
</div>