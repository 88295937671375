import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import firebase from 'firebase';
import { BehaviorSubject, Observable } from 'rxjs';
import { LocalStorageService, StorageKey } from 'src/app/local-storage.service';
import { map, catchError } from 'rxjs/operators';
import { AllToursFunction, runFunction } from 'src/app/utils/functions';

@Injectable()
export class PartnerService {
  constructor(private storage: LocalStorageService,) {
    firebase.auth().onAuthStateChanged(async user => {
      if (user) {
        await this.loggedInAsPartner()
        this.listenToDoc(user.uid)
        this.storage.setItem(StorageKey.USER_ID, user.uid);
        this.storage.setItem(StorageKey.USER_EMAIL, user.email);
      }
    });
  }

  isLoggedIn = new BehaviorSubject<boolean>(false);
  isLoggedIn$ = this.isLoggedIn.asObservable();

  partner = new BehaviorSubject<any>(undefined);
  partner$ = this.partner.asObservable();

  paymentLinkLoading = true
  needsPaymentSetup = false;
  stripeLink
  isIndividual = false

  async loggedInAsPartner() {
    const user = await new Promise<firebase.User | null>((resolve) => {
      const unsubscribe = firebase.auth().onAuthStateChanged((user) => {
        unsubscribe(); // Unsubscribe after we get the result
        resolve(user); // Resolve with the current user (or null if not signed in)
      });
    });
    console.log('user is null', user == null)
    if (!user) return false
    if (this.partner.value != undefined && this.partner.value.authId === user.uid) return true
    let doc = await firebase.firestore().collection('partners').where('authId', '==', user.uid).get()
    console.log('loggedInAsPartner doc', doc.docs.length)
    let loggedInAsPartner = doc.docs.length > 0
    if (loggedInAsPartner) {
      this.setPartner(doc.docs[0].data())
    }
    this.isLoggedIn.next(loggedInAsPartner);
    return loggedInAsPartner
  }

  async login(email: string, password: string, onLogin: (success, error) => void) {
    firebase.auth().signInWithEmailAndPassword(email, password)
      .then(async (userCredential) => {
        var user = userCredential.user;
        let loggedInAsPartner = await this.loggedInAsPartner()
        this.isLoggedIn.next(loggedInAsPartner);
        this.listenToDoc(user.uid)
        onLogin(true, null)
      })
      .catch((error) => {
        var errorMessage = error.message;
        console.error("error", errorMessage)
        onLogin(false, errorMessage)
      })
  }

  listenToDoc(id) {
    firebase.firestore().collection('partners').where('authId', '==', id).onSnapshot(snapshot => {
      if (snapshot.docs.length > 0) {
        this.setPartner(snapshot.docs[0].data())
      }
    });
  }

  setPartner(partner) {
    this.isIndividual = partner.partnerType == 'individual'
    this.partner.next(partner)
    this.storage.setItem(StorageKey.PARTNER_ID, partner.id);
    this.storage.setItem(StorageKey.STRIPE_ACCOUNT_ID, partner.stripeAccountId);
  }

  getPartnerId() {
    return this.storage.getItem(StorageKey.PARTNER_ID) || (this.partner.value != undefined ? this.partner.value.id : undefined)
  }

  getStripeAccountId() {
    return this.storage.getItem(StorageKey.STRIPE_ACCOUNT_ID) || (this.partner.value != undefined ? this.partner.value.stripeAccountId : undefined)
  }

  async getStripeAccount() {
    let partnerId = this.getPartnerId()
    let stripeAccountId = this.getStripeAccountId()
    let account = await runFunction(AllToursFunction.getPartnerStripeAccount, { partnerId, stripeAccountId })
    return account
  }

  async getStripeOnboardingLink() {
    let partnerId = this.getPartnerId()
    let email = this.partner.value != undefined ? this.partner.value.email : this.storage.getItem(StorageKey.USER_EMAIL)
    console.log('getStripeOnboardingLink', partnerId, email)
    let request = email == undefined ? { partnerId } : { partnerId, email }
    let link = await runFunction(AllToursFunction.getPartnerStripeOnboardingLink, request)
    return link
  }

  async getStripeDashboardLink() {
    let partnerId = this.getPartnerId()
    let stripeAccountId = this.getStripeAccountId()
    let link = await runFunction(AllToursFunction.getPartnerStripeDashboardLink, { partnerId, stripeAccountId })
    return link
  }

  needsToDoAccountSetup(account) {
    return !account.payouts_enabled
  }

  async loadStripeLink() {
    if (this.paymentLinkLoading == false && this.needsPaymentSetup == false && this.stripeLink != undefined) {
      return this.stripeLink
    }
    this.paymentLinkLoading = true
    let stripeAccount = await this.getStripeAccount()
    this.needsPaymentSetup = stripeAccount == undefined || this.needsToDoAccountSetup(stripeAccount)
    if (this.needsPaymentSetup) {
      this.stripeLink = await this.getStripeOnboardingLink()
    } else {
      this.stripeLink = await this.getStripeDashboardLink()
    }
    this.paymentLinkLoading = false
  }

  isPartnerInCity(cityId) {
    let partner = this.partner.value
    if (partner == undefined || partner.cities == undefined) return false
    return partner.cities.find(city => city.id == cityId) != undefined
  }

  logout() {
    firebase.auth().signOut().then(() => {
      this.isLoggedIn.next(false);
      this.partner.next(undefined);
    });
  }

}

@Injectable()
export class PartnerAuthGuard {
  constructor(
    private _authService: PartnerService,
    private router: Router
  ) { }

  async canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<boolean> {
    try {
      const isPartnerLoggedIn = await this._authService.loggedInAsPartner();
      if (isPartnerLoggedIn) {
        return true; // Allow route activation
      } else {
        this.router.navigate(['/become-a-partner']); // Redirect to login if not authenticated
        return false;
      }
    } catch (error) {
      console.error('Error checking partner auth:', error);
      this.router.navigate(['/become-a-partner']); // Redirect in case of error
      return false;
    }
  }
}
