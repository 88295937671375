<h2 style="padding: 1vh; text-align: center">Landmark Editor</h2>
<div class="table-responsive">
  <table class="table table-bordered">
    <thead class="thead-dark">
      <tr>
        <th scope="col">Current</th>
        <th scope="col">New</th>
      </tr>
    </thead>
    <tbody *ngIf="currentLandmark != undefined">
      <tr>
        <td>
          <textarea
            [disabled]="true"
            class="form-control"
            style="height: 60vh"
            [(ngModel)]="currentLandmark.text"></textarea>
        </td>
        <td>
          <textarea class="form-control" style="height: 60vh" [(ngModel)]="newLandmark.text"></textarea>
        </td>
      </tr>
      <tr>
        <td style="width: 50%">
          <div style="display: flex">
            <button type="button" (click)="playCurrentAudio()" class="btn btn-primary" style="font-size: 24px">
              <i *ngIf="currentAudio.paused" class="fa fa-play-circle icons"></i>
              <i *ngIf="!currentAudio.paused" class="fa fa-pause-circle icons"></i>
            </button>
            <p style="margin-left: 8px">{{ currentLandmark.audioUrl }}</p>
          </div>
        </td>
        <td style="width: 50%">
          <div *ngIf="currentLandmark.audioUrl != newLandmark.audioUrl" style="display: flex">
            <button type="button" (click)="playNewAudio()" class="btn btn-primary" style="font-size: 24px">
              <i *ngIf="newAudio.paused" class="fa fa-play-circle icons"></i>
              <i *ngIf="!newAudio.paused" class="fa fa-pause-circle icons"></i>
            </button>
            <p style="margin-left: 8px">{{ newLandmark.audioUrl }}</p>
          </div>
          <div style="display: flex; padding-top: 16px">
            <div style="width: 50%">
              <button type="button" (click)="generateNewScript()" class="btn btn-primary">Generate New Script</button>
            </div>
            <div style="width: 50%; text-align: end">
              <button [disabled]="!hasChanged()" type="button" (click)="generateNewAudio()" class="btn btn-primary">
                Generate New Audio
              </button>
            </div>
          </div>
        </td>
      </tr>
    </tbody>
  </table>
</div>
<div style="text-align: center; padding: 64px" *ngIf="loading">
  <p>Loading...</p>
</div>
<div class="bottom-bar">
  <div>
    <button
      [disabled]="currentLandmark.audioUrl == newLandmark.audioUrl"
      class="btn btn-primary"
      (click)="updateLandmark()">
      Update
    </button>
  </div>
</div>
