import { Component, OnInit } from '@angular/core';
import firebase from 'firebase';

@Component({
  selector: 'app-admin-tours',
  templateUrl: './admin-tours.component.html',
  styleUrls: ['./admin-tours.component.css'],
})
export class AdminToursComponent implements OnInit {
  tours = [];
  startAtDoc;
  endOfData = false;
  loading = false;

  constructor() {}

  ngOnInit() {
    this.tours = [];
    this.getMoreData(5);
  }

  getMoreData(limit: number) {
    this.loading = true;
    var query = firebase.firestore().collection('guided-tours').orderBy('created', 'desc').limit(limit);

    if (this.startAtDoc != undefined) {
      query = firebase
        .firestore()
        .collection('guided-tours')
        .orderBy('created', 'desc')
        .limit(limit)
        .startAfter(this.startAtDoc)
        .limit(limit);
    }

    query
      .get()
      .then(querySnapshot => {
        this.endOfData = querySnapshot.docs.length < limit;
        querySnapshot.docs.forEach(doc => {
          this.tours.push(doc.data());
        });

        this.startAtDoc = querySnapshot.docs[querySnapshot.docs.length - 1];
        this.loading = false;
      })
      .catch(error => {
        console.log('Error getting documents: ', error);
        this.loading = false;
      });
  }

  deleteTour(tour) {
    this.tours = this.tours.filter(t => t.id != tour.id);
    firebase
      .firestore()
      .collection('guided-tours')
      .doc(tour.id)
      .delete()
      .then(() => {
        console.log('Document successfully deleted!');
      })
      .catch(error => {
        console.error('Error removing document: ', error);
      });
  }

  toggleReviewed(tour) {
    tour.qualityReviewed = !tour.qualityReviewed;
    firebase
      .firestore()
      .collection('guided-tours')
      .doc(tour.id)
      .update({
        qualityReviewed: tour.qualityReviewed,
      })
      .then(() => {
        console.log('Document successfully updated!');
      })
      .catch(error => {
        console.error('Error updating document: ', error);
      });
  }

  getDate(milliseconds) {
    return new Date(milliseconds).toLocaleString();
  }
}
