import { Component, OnInit } from '@angular/core';
import { NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Router } from '@angular/router';
import { PartnerService } from './partner-service';

@Component({
  selector: 'app-partner',
  templateUrl: './partner.component.html',
  styleUrls: ['./partner.component.css', '../../app.component.css'],
})
export class PartnerComponent implements OnInit {

  constructor(public partnerService: PartnerService, private router: Router) {
  }

  ngOnInit() { }

  logout() {
    this.partnerService.logout()
    this.router.navigate([''])
  }
}


