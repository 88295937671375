import { Component, Input, OnInit } from '@angular/core';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-tour-review',
  templateUrl: './tour-review.component.html',
  styleUrls: ['./tour-review.component.css'],
})
export class TourReviewComponent implements OnInit {
  @Input() review;

  constructor() {}

  ngOnInit() {}
}
