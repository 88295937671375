import { Injectable } from '@angular/core';
import { Purchases } from '@revenuecat/purchases-js';
import firebase from 'firebase';
import { LocalStorageService, StorageKey } from 'src/app/local-storage.service';

@Injectable({
  providedIn: 'root',
})
export class PurchasesService {
  purchases: Purchases

  constructor(private localStorage: LocalStorageService) {
  }

  async initRevenueCat(userId) {
    this.purchases = Purchases.configure("rcb_nderXDuYpUMhwzJsSjhjrvHErJjG", userId);
    let customerInfo = await this.purchases.getCustomerInfo()
    console.log('customerInfo', customerInfo)
  }

  async getCheckoutUrl(tourId, priceId, cancelUrl, successUrl, userId, userEmail) {
    let result = await firebase.functions().httpsCallable('createCheckoutSession')({
      tourId: tourId,
      productId: priceId,
      cancelUrl: cancelUrl,
      successUrl: successUrl,
      language: 'en',
      userId: userId,
      email: userEmail
    });
    return result.data;
  }

  async getPriceConfig(landmarkCount, currency = "usd") {
    let prices = await this.getTourPrices(currency)
    let priceConfig = undefined;
    prices.forEach(option => {
      if (landmarkCount >= option.min && landmarkCount <= option.max) {
        priceConfig = option;
      }
    });
    if (priceConfig == undefined) {
      priceConfig = prices[prices.length - 1]
    }
    return priceConfig;
  }

  async getLocalPrice(tour) {
    let landmarkCount = tour.landmarkCount ? tour.landmarkCount : tour.landmarks.filter(l => l.latLng != undefined).length
    let price = await this.getPriceConfig(landmarkCount);
    return price
  }

  async getTourPrices(currency = "usd") {
    var prices
    var pricesJson = localStorage.getItem(StorageKey.TOUR_PRICES + currency) as any

    if (pricesJson == undefined) {
      let priceResponse = await firebase.functions().httpsCallable("getTourPrices")({ currency: currency })
      prices = priceResponse.data
      localStorage.setItem(StorageKey.TOUR_PRICES + currency, JSON.stringify(prices))
    } else {
      prices = JSON.parse(pricesJson)
    }

    console.log('prices', prices, "currency", currency)

    return prices
  }
}
