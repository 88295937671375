import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import stringsJson from '../../../../assets/strings/strings.json';
import citiesJson from '../../../../assets/json/mini_cities.json';
import firebase from 'firebase';

@Component({
  selector: 'app-tour-reviews',
  templateUrl: './tour-reviews.component.html',
  styleUrls: ['./tour-reviews.component.css'],
})
export class TourReviewsComponent implements OnInit {
  cities = citiesJson.cities;
  city;
  language;
  cityId;
  tourId;
  strings;
  reviews = [];
  loading = true;

  constructor(private route: ActivatedRoute) {}

  ngOnInit() {
    this.language = window.location.hostname.split('.')[0];
    if (this.language == undefined || this.language.length > 2) this.language = 'en';
    this.strings = JSON.parse(JSON.stringify(stringsJson))['default'][this.language];
    this.cityId = this.route.snapshot.paramMap.get('cityId');
    this.tourId = this.route.snapshot.paramMap.get('tourId');
    this.city = this.cities.find(c => c.id == this.cityId);
    this.getReviews();
  }

  getReviews() {
    firebase
      .firestore()
      .collection('guided-tours')
      .doc(this.tourId)
      .collection('reviews')
      .get()
      .then(querySnapshot => {
        querySnapshot.docs.forEach(doc => {
          doc.data().reviews.forEach(review => {
            this.reviews.push(review);
          });
        });
        this.loading = false;
      });
  }
}
