import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import firebase from 'firebase';

@Component({
  selector: 'app-admin-landmark-editor',
  templateUrl: './admin-landmark-editor.component.html',
  styleUrls: [
    './admin-landmark-editor.component.css',
    '../admin-editor.component.css',
    '../../../../app.component.css',
  ],
})
export class AdminLandmarkEditorComponent implements OnInit {
  loading = true;
  tourId;
  landmarkId;
  tour;
  currentLandmark;
  newLandmark;
  currentAudio = new Audio();
  newAudio = new Audio();
  hasNewAudio = false;

  constructor(private route: ActivatedRoute) {}

  ngOnInit() {
    this.getTour();
  }

  getTour() {
    this.tourId = this.route.snapshot.paramMap.get('tourId');
    this.landmarkId = this.route.snapshot.paramMap.get('landmarkId');
    firebase
      .firestore()
      .collection('guided-tours')
      .where('id', '==', this.tourId)
      .get()
      .then(querySnapshot => {
        this.tour = querySnapshot.docs[0].data();
        this.currentLandmark = this.tour.landmarks.find(landmark => landmark.id == this.landmarkId);
        this.newLandmark = { ...this.currentLandmark };
        this.loading = false;
        this.loadAudios();
      });
  }

  hasChanged() {
    return JSON.stringify(this.currentLandmark) != JSON.stringify(this.newLandmark);
  }

  loadAudios() {
    this.currentAudio.src = this.currentLandmark.audioUrl;
    this.currentAudio.load();

    this.newAudio.src = this.newLandmark.audioUrl;
    this.newAudio.load();
  }

  async playCurrentAudio() {
    if (this.currentAudio.paused) {
      console.log(this.currentAudio);
      await this.currentAudio.play();
    } else {
      this.currentAudio.pause();
    }
  }

  async playNewAudio() {
    if (this.newAudio.paused) {
      await this.newAudio.play();
    } else {
      this.newAudio.pause();
    }
  }

  async generateNewScript() {}

  async generateNewAudio() {}

  async updateLandmark() {}
}
