import { Component, OnInit } from '@angular/core';
import { PosterContent } from './PosterContent';
import { PartnerService } from '../../partner-service';
import countriesAndCurrency from '../../../../../assets/json/countries_currency.json';
import firebase from 'firebase';
import { PurchasesService } from 'src/app/alltours-app/user/purchases.service';
import { LocalStorageService, StorageKey } from 'src/app/local-storage.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-partner-poster-editor',
  templateUrl: './partner-poster-editor.component.html',
  styleUrls: ['./partner-poster-editor.component.css', '../../../../app.component.css'],
})
export class PartnerPosterEditorComponent implements OnInit {

  constructor(
    public partnerService: PartnerService,
    private purchaseService: PurchasesService,
    private storageService: LocalStorageService,
    private route: ActivatedRoute,
    private router: Router
  ) { }

  posterContent: PosterContent
  partner
  needsChromePrintHint
  posterId
  posterIds = [1, 2]

  ngOnInit() {
    this.posterId = this.route.snapshot.queryParams.id
    console.log('posterId', this.posterId)
    this.loadDefaultPoster()
    console.log('navigator.userAgent', navigator.userAgent)
    this.needsChromePrintHint = navigator.userAgent.match(/Chrome/i) && this.storageService.getItem(StorageKey.PARTNERS_PRINTED_ONCE) == undefined
  }

  printPage() {
    window.print();
    this.storageService.setItem(StorageKey.PARTNERS_PRINTED_ONCE, "true")
    this.needsChromePrintHint = false
  }

  loadDefaultPoster() {
    this.partnerService.partner$.subscribe(partner => {
      if (!partner) {
        return
      }
      this.partner = partner
      this.partner.cities.forEach(city => {
        city.marketing.link = city.marketing.link.replace('https://', '')
      })
      this.selectCity(partner.cities[0])
    })
  }

  async selectCity(city) {
    city.currency = countriesAndCurrency.countries.find(country => country.id === city.countryCode).currency
    var landmarkCount = 8
    let shortTourDocs = await firebase.firestore().collection('tours').where('city.id', '==', city.id).orderBy('landmarkCount').limit(1).get()
    if (!shortTourDocs.empty) {
      landmarkCount = shortTourDocs.docs[0].data().landmarkCount
    }
    let price = (await this.purchaseService.getPriceConfig(landmarkCount, city.currency.id)).price
    console.log("price", price)
    this.posterContent = {
      city: city,
      price: price,
      partnerName: this.partner.businessName || this.partner.name,
    }
  }

  onCityChange(event: any) {
    let cityId = event.target.value
    let city = this.partner.cities.find(city => String(city.id) == String(cityId))
    this.selectCity(city)
  }

  onPosterChange(event: any) {
    let posterId = event.target.value
    this.router.navigate([], { queryParams: { id: posterId } })
  }
}