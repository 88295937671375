<div style="background: #fefcf4; padding-top: 16px">
  <div class="container">
    <div class="row">
      <div class="col-xl-6 col-12" *ngFor="let screenshot of screenshots; index as i">
        <div style="margin: 16px">
          <img
            style="border-radius: 32px"
            width="100%"
            src="..\assets\images\explorer\screenshots\{{ screenshot }}.jpg" />
        </div>
      </div>
    </div>
  </div>
</div>
