<div id="wrapper">
  <div id="pageBackground">
    <div *ngIf="loadingPage" class="loadingPage">
      <div style="margin: auto;">
        <div class="loader" style="margin: auto; padding: 8px; margin-bottom: 8px;"></div>
        Loading...
      </div>
    </div>
    <router-outlet></router-outlet>
  </div>
  <div id="footer" style="width: 100%; background: #fefcf4">
    <div class="container page-footer white font-small" style="color: grey">
      <div class="row">
        <div class="text-center py-3 col-xl-4 col-sm-6 col-sx-12" style="margin: auto; font-size: 0.75rem">
          <a routerLink="/policy" [queryParams]="{ allTours: true }">{{ strings.privacy_policy }}</a>
        </div>
        <div class="text-center py-3 col-xl-4 col-sm-6 col-sx-12" style="margin: auto; font-size: 0.75rem">
          © {{ todaysDate | date: 'yyyy' }} {{ strings.copyright }}: <a href="https://alltours.app">alltours.app</a>
        </div>
        <div class="text-center py-3 col-xl-4 col-sm-6 col-sx-12" style="margin: auto; font-size: 0.75rem">
          <a routerLink="/terms">{{ strings.terms }}</a>
        </div>
        <div class="text-center py-3 col-xl-4 col-sm-6 col-sx-12" style="margin: auto; font-size: 0.75rem">
          <a routerLink="/contact">{{ strings.contact_us }}</a>
        </div>
        <div class="text-center py-3 col-xl-4 col-sm-6 col-sx-12" style="margin: auto; font-size: 0.75rem">
          <a href="https://explorer-app.co.uk">Explorer</a>
        </div>
      </div>
    </div>
  </div>
</div>

<div id="appComponentLoginModal" class="modal" tabindex="-1" style="color: #000">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Login</h5>
        <button id="closeLoginBtn" type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body" style="color: #000a">
        <app-user-login [loginMessage]="'Login to purchase tours...'"></app-user-login>
      </div>
    </div>
  </div>
</div>