<div style="background: #fafafa">
  <div style="display: flex">
    <div style="width: 100%">
      <img style="width: 100%; height: 90vh; object-fit: cover"
        src="../../../assets/images/alltours/screenshots/audio.jpg" />
    </div>
    <div class="imgCover" style="height: 90vh">
      <div class="landingContent">
        <div class="centralMedia">
          <div id="ctaHolder">
            <h1 i18n id="landingTitle">Earn <span style="color: #daff5e">40%</span><br />commission<span>*</span></h1>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="container">
    <div class="row" style="place-content: center; color: #000a">
      <div class="col-12 card cardContent">
        <div class="content">

          <h4>Signup for free & start earning today</h4>
          <h6 class="lightSubheader">Easy signup. Unlimited earning potential.</h6>
          <button class="ctaBtn" (click)="signup()" style="margin-top: 16px;">Become A Partner</button><br />
          <div style="font-size: 12px; color: #0008; text-align: center; margin-top: 10vh">
            *on every sale of an audio tour within 2 weeks of a new user visiting your partner link.
          </div>
        </div>

        <div class="accordion accordion-flush" style="padding: 0">

          <div class="accordion-item">
            <h2 class="accordion-header" id="whatIsHeader">
              <button class="accordion-button collapsed sectionHeader" type="button" data-bs-toggle="collapse"
                data-bs-target="#whatIsContainer" aria-expanded="false" aria-controls="whatIsContainer">
                What is AllTours?
              </button>
            </h2>
            <div id="whatIsContainer" class="accordion-collapse collapse show" aria-labelledby="whatIsHeader">
              <div class="accordion-body" style="padding-bottom: 64px;">
                <div class="row">
                  <div class="col-12" style="margin-bottom: 64px;">
                    <div style="display: flex; justify-content: center;">
                      <img style="height: 76px;" src="../../../../../assets/images/alltours/logo_app.png" />
                      <div style="padding-left: 16px;">
                        <h5 class="primaryText" style="margin-bottom: 4px;">AllTours: Audio Tours App
                        </h5>
                        <p class="secondaryText">Entertaining, budget-friendly, self-guided audio tours</p>
                        <a (click)="goToAppStore()" target="_blank"
                          style="margin-right: 16px; color: #94b624; text-decoration: underline; cursor: pointer;">
                          Try the app <span class="material-symbols-rounded">arrow_forward</span>
                        </a>
                      </div>
                    </div>
                  </div>
                  <div class="col-12 col-lg-6 col-xl-4 howItWorksContainer" *ngFor="let example of whatIsAllTours">
                    <div class="image-crop"><img style="width: 100%; max-width: 200px;"
                        src="../../../../../assets/images/alltours/screenshots/{{ example.image }}"></div>
                    <div>
                      <b class="primaryText">{{ example.title }}</b>
                      <p class="secondaryText">{{ example.description }}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="accordion-item">
            <h2 class="accordion-header" id="howitWorksHeader">
              <button class="accordion-button collapsed sectionHeader" type="button" data-bs-toggle="collapse"
                data-bs-target="#howItWorksContainer" aria-expanded="false" aria-controls="howItWorksContainer">
                How does the partnership work?
              </button>
            </h2>
            <div id="howItWorksContainer" class="accordion-collapse collapse " aria-labelledby="howitWorksHeader">
              <div class="accordion-body">
                <div class="row">
                  <div class="col-12 col-lg-6 col-xl-3 howItWorksContainer" *ngFor="let step of howItWorks">
                    <div><img style="width: 100%; max-width: 200px;"
                        src="../../../../../assets/images/alltours/{{ step.image }}"></div>
                    <div style="margin-top: 16px;">
                      <b class="primaryText">{{ step.title }}</b>
                      <p class="secondaryText">{{ step.description }}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="accordion-item">
            <h2 class="accordion-header" id="howMuchHeader">
              <button class="accordion-button collapsed sectionHeader" type="button" data-bs-toggle="collapse"
                data-bs-target="#howMuchContainer" aria-expanded="false" aria-controls="howMuchContainer">
                How much can I earn?
              </button>
            </h2>
            <div id="howMuchContainer" class="accordion-collapse collapse " aria-labelledby="howMuchHeader">
              <div class="accordion-body">
                <div class="row" style="padding-bottom: 64px;">
                  <div class="col-12 secondaryText" style="margin-bottom: 64px;">
                    <p style="font-size: 20px;">
                      (All numbers in USD)
                    </p>
                    On AllTours, users can either purchase individual tours or subscribe to an Unlimited plan. As a
                    partner, you can earn from sales of both.
                  </div>
                  <div class="col-12" style="text-align: center;">
                    <div class="calculatorContainer">
                      <h5>Earnings calculator</h5>
                      <div style="margin-top: 24px; margin-bottom: 24px;">
                        <p class="secondaryText">{{ tourSalesRange }} tour sales / day</p>
                        <p class="secondaryText">{{ (tourSalesRange / 10) | number: '1.0-0' }} subscription sales /
                          day
                        </p>
                        <input [(ngModel)]="tourSalesRange" type="range" class="form-range" min="1" max="100" step="1"
                          id="tourSalesRange">
                      </div>
                      <p style="font-size: 24px; margin-bottom: 0px;">
                        <span style="color: #027b02; font-weight: bold;">
                          {{ ((tourSalesRange * averageTourPrice * 0.4 * 30) + ((tourSalesRange / 10) *
                          averageSubscriptionPrice * 0.3 * 30)) | currency : 'USD' : 'symbol' : '1.0-0' }}
                        </span> / month
                      </p>
                    </div>
                  </div>
                  <div class="col-12 col-lg-6" style="margin-top: 32px;">
                    <div class="table-responsive">
                      <table class="table">
                        <thead class="thead-dark">
                          <tr>
                            <th scope="col">Tour</th>
                            <th scope="col">Price</th>
                            <th scope="col">Commission</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr *ngFor="let info of tourEarnings; let i = index">
                            <td [ngStyle]="{'color': i == 1 ? '#000' : '#0008' }">
                              <img style="width: 20px; margin-right: 16px;" [ngStyle]="{'opacity': i == 1 ? 1 : 0.5 }"
                                src="../../../../../assets/images/alltours/logo.png" />
                              {{ info.name }}
                            </td>
                            <td [ngStyle]="{'color': i == 1 ? '#000' : '#0008' }">
                              {{ info.price | currency: 'USD' }}
                            </td>
                            <td [ngStyle]="{'color': i == 1 ? '#000' : '#0008' }">
                              {{ info.price * info.commissionRate | currency: 'USD' }}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <p class="secondaryText">Tour sales pay a 40% commission</p>
                  </div>
                  <div class="col-12 col-lg-6" style="margin-top: 32px;">
                    <div class="table-responsive">
                      <table class="table">
                        <thead class="thead-dark">
                          <tr>
                            <th scope="col">Unlimited</th>
                            <th scope="col">Price</th>
                            <th scope="col">Commission</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr *ngFor="let info of unlimitedEarnings; let i = index">
                            <td [ngStyle]="{'color': i == 1 ? '#000' : '#0008' }">
                              <img style="width: 20px; margin-right: 16px;" [ngStyle]="{'opacity': i == 1 ? 1 : 0.5 }"
                                src="../../../../../assets/images/alltours/unlimited_logo.png" />
                              {{ info.name }}
                            </td>
                            <td [ngStyle]="{'color': i == 1 ? '#000' : '#0008' }">
                              {{ info.price | currency: 'USD' }}
                            </td>
                            <td [ngStyle]="{'color': i == 1 ? '#000' : '#0008' }">
                              {{ info.price * info.commissionRate | currency: 'USD' }}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <p class="secondaryText">Unlimited subscription sales pay a 30% commission</p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="accordion-item">
            <h2 class="accordion-header" id="whyBePartnerHeader">
              <button class="accordion-button collapsed sectionHeader" type="button" data-bs-toggle="collapse"
                data-bs-target="#whyBePartnerContainer" aria-expanded="false" aria-controls="whyBePartnerContainer">
                Why be a partner?
              </button>
            </h2>
            <div id="whyBePartnerContainer" class="accordion-collapse collapse " aria-labelledby="whyBePartnerHeader">
              <div class="accordion-body">
                <ul>
                  <li *ngFor="let item of whyBePartner" class="infoItem">
                    <b class="primaryText">{{ item.title }}</b>
                    <p class="secondaryText" [innerHtml]="item.description"></p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="accordion-item">
            <h2 class="accordion-header" id="faqsHeader">
              <button class="accordion-button collapsed sectionHeader" type="button" data-bs-toggle="collapse"
                data-bs-target="#faqsContainer" aria-expanded="false" aria-controls="faqsContainer">
                FAQs
              </button>
            </h2>
            <div id="faqsContainer" class="accordion-collapse collapse " aria-labelledby="faqsHeader">
              <div class="accordion-body">
                <ul>
                  <li *ngFor="let item of faqs" class="infoItem">
                    <b class="primaryText">{{ item.title }}</b>
                    <p class="secondaryText">{{ item.description }}</p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>