import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class LocalStorageService {
  constructor() { }

  setItem(key: StorageKey, value: any) {
    localStorage.setItem(key, value);
  }

  getItem(key: StorageKey) {
    return localStorage.getItem(key);
  }

  removeItem(key: StorageKey) {
    localStorage.removeItem(key);
  }
}

export enum StorageKey {
  USER_ID = 'userId',
  PARTNER_ID = 'partnerId',
  USER_EMAIL = 'userEmail',
  STRIPE_ACCOUNT_ID = 'stripeAccountId',
  PARTNERS_PRINTED_ONCE = "partnersPrintedOnce",
  HAS_CLICKED_DOWNLOAD_APP = "hasClickedDownloadApp",
  TOUR_PRICES = "tourPrices"
}
