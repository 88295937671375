// animations.ts
import { trigger, state, style, transition, animate } from '@angular/animations';

export const slideInOutAnimation = trigger('slideInOut', [
  state('in', style({
    transform: 'translateY(0)'
  })),
  state('out', style({
    transform: 'translateY(100%)'
  })),
  transition('in => out', [
    animate('0.25s ease-out')
  ]),
  transition('out => in', [
    animate('0.25s ease-in')
  ])
]);

export const fadeInUpAnimation = trigger('fadeInUp', [
  state('in', style({
    opacity: 1,
    transform: 'translateY(0)'
  })),
  state('out', style({
    opacity: 0,
    transform: 'translateY(16px)'
  })),
  transition('out => in', [
    animate('0.25s ease-in', style({
      opacity: 1,
      transform: 'translateY(0)'
    }))
  ]),
  transition('in => out', [
    animate('0.25s ease-out', style({
      opacity: 0,
      transform: 'translateY(16px)'
    }))
  ])
]);

export const fadeAnimation = trigger('fade', [
  state('in', style({
    opacity: 1,
    display: 'block'
  })),
  state('out', style({
    opacity: 0,
    display: 'none'
  })),
  transition('in => out', [
    animate('0.25s ease-out', style({
      opacity: 0,
    }))
  ]),
  transition('out => in', [
    animate('0.25s ease-in', style({
      opacity: 1,
      display: 'block'
    }))
  ])
]);