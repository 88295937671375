<div id="wrapper">
  <div id="pageBackground">
    <nav
      style="box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2)"
      class="navbar sticky-top navbar-expand-lg navbar-light bg-light">
      <a class="navbar-brand" routerLink="/explorer/home">
        <div style="display: flex">
          <img id="landingIcon" src="../../assets/images/explorer/logo.png" />
          <span id="landingText">Explorer</span>
        </div>
      </a>
      <button
        class="navbar-toggler"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#navbarNav"
        aria-controls="navbarNav"
        aria-expanded="false"
        aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarNav">
        <ul class="navbar-nav ml-auto">
          <li class="nav-item">
            <button type="button" (click)="goToApp()" class="ctaBtnMini">Start Exploring</button>
          </li>
        </ul>
      </div>
    </nav>
    <router-outlet></router-outlet>
  </div>
  <div class="col-12" style="background: #fefcf4; text-align: center">
    <button type="button" (click)="goToApp()" style="margin: 64px; background: #3b4d00" class="ctaBtnMini">
      Start Exploring
    </button>
  </div>
  <div style="width: 100%; background: #fefcf4">
    <div class="container page-footer white font-small" style="color: grey">
      <div class="row">
        <div class="text-center py-3 col-xl-4 col-sm-6 col-sx-12" style="margin: auto; font-size: 0.75rem">
          <a routerLink="/explorer/policy" [queryParams]="{ allTours: false }">Privacy policy</a>
        </div>
        <div class="text-center py-3 col-xl-4 col-sm-6 col-sx-12" style="margin: auto; font-size: 0.75rem">
          © {{ todaysDate | date: 'yyyy' }} Copyright: <a href="https://explorer-app.co.uk">explorer-app.co.uk</a>
        </div>
        <div class="text-center py-3 col-xl-4 col-sm-6 col-sx-12" style="margin: auto; font-size: 0.75rem">
          <a href="mailto:kes@explorer-app.co.uk">Contact us</a>
        </div>
        <div class="text-center py-3 col-xl-4 col-sm-6 col-sx-12" style="margin: auto; font-size: 0.75rem">
          <a href="https://alltours.app">AllTours</a>
        </div>
      </div>
    </div>
  </div>
</div>
