import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AllToursHomeComponent } from './alltours-app/user/home/all-tours-home.component';
import { AppComponent } from './app.component';
import { ExplorerAppComponent } from './explorer-app/explorer-app.component';
import { HomeComponent } from './explorer-app/home/home.component';
import { MapComponent } from './explorer-app/map/map.component';
import { PolicyComponent } from './explorer-app/policy/policy.component';
import { CitiesComponent } from './alltours-app/user/cities/cities.component';
import { CityComponent } from './alltours-app/user/city/city.component';
import { TermsComponent } from './alltours-app/user/terms/terms.component';
import { TourComponent } from './alltours-app/user/tour/tour.component';
import { ReviewsComponent } from './explorer-app/reviews/reviews.component';
import { TourReviewComponent } from './alltours-app/user/tour-review/tour-review.component';
import { TourReviewsComponent } from './alltours-app/user/tour-reviews/tour-reviews.component';
import { GetTourComponent } from './alltours-app/user/get-tour/get-tour.component';
import { ContactUsComponent } from './alltours-app/user/contact-us/contact-us.component';
import { ProductComponent } from './alltours-app/admin/product/product.component';
import { DeleteAccountComponent } from './alltours-app/user/delete-account/delete-account.component';
import { UnlimitedComponent } from './alltours-app/user/unlimited/unlimited.component';
import { BetaComponent } from './alltours-app/user/beta/beta.component';
import { AdminComponent } from './alltours-app/admin/admin.component';
import { AdminAuthGuard } from './alltours-app/admin/admin-auth-service';
import { AdminLoginComponent } from './alltours-app/admin/admin-login/admin-login.component';
import { FeedbackComponent } from './alltours-app/admin/feedback/feedback.component';
import { AdminDashboardComponent } from './alltours-app/admin/admin-dashboard/admin-dashboard.component';
import { AdminToursComponent } from './alltours-app/admin/admin-tours/admin-tours.component';
import { AdminEditorComponent } from './alltours-app/admin/admin-editor/admin-editor.component';
import { AdminLandmarkEditorComponent } from './alltours-app/admin/admin-editor/admin-landmark-editor/admin-landmark-editor.component';
import { AdminRedemptionCodesComponent } from './alltours-app/admin/admin-redemption-codes/admin-redemption-codes.component';
import { AdminReviewsComponent } from './alltours-app/admin/admin-reviews/admin-reviews.component';
import { PartnerComponent } from './alltours-app/partner/partner.component';
import { PartnerDashboardComponent } from './alltours-app/partner/partner-dashboard/partner-dashboard.component';
import { PartnerLoginComponent } from './alltours-app/partner/become-a-partner/partner-login/partner-login.component';
import { PartnerSignupComponent } from './alltours-app/partner/become-a-partner/partner-signup/partner-signup.component';
import { PartnerAuthGuard } from './alltours-app/partner/partner-service';
import { BecomeAPartnerComponent } from './alltours-app/partner/become-a-partner/become-a-partner.component';
import { PartnerMarketingComponent } from './alltours-app/partner/partner-marketing/partner-marketing.component';
import { PartnerStatisticsComponent } from './alltours-app/partner/partner-statistics/partner-statistics.component';
import { PartnerPosterComponent1 } from './alltours-app/partner/partner-marketing/partner-poster-editor/partner-poster/partner-poster.component';
import { PartnerMarketingDashboardComponent } from './alltours-app/partner/partner-marketing/partner-marketing-dashboard/partner-marketing-dashboard.component';
import { PartnerTermsComponent } from './alltours-app/partner/partner-terms/partner-terms.component';
import { TourPurchasedComponent } from './alltours-app/user/tour-purchased/tour-purchased.component';
import { UserProfileComponent } from './alltours-app/user/user-section/user-profile/user-profile.component';
import { UserAuthGuard } from './alltours-app/user/user.service';
import { UserSectionComponent } from './alltours-app/user/user-section/user-section.component';
import { UserComponent } from './alltours-app/user/user.component';
import { PartnerAdComponent } from './alltours-app/partner/become-a-partner/partner-ad/partner-ad.component';
import { PartnerPosterEditorComponent } from './alltours-app/partner/partner-marketing/partner-poster-editor/partner-poster-editor.component';
import { PartnerPosterComponent2 } from './alltours-app/partner/partner-marketing/partner-poster-editor/partner-poster-2/partner-poster-2.component';

const appRoutes: Routes = [
  {
    path: '', component: UserComponent, children: [
      { path: '', component: AllToursHomeComponent },
      { path: 'policy', component: PolicyComponent },
      { path: 'terms', component: TermsComponent },
      { path: 'contact', component: ContactUsComponent },
      { path: 'product', component: ProductComponent },
      { path: 'delete', component: DeleteAccountComponent },
      { path: 'unlimited', component: UnlimitedComponent },
      { path: 'beta', component: BetaComponent },
      { path: 'cities', component: CitiesComponent },
      { path: 'tour/:countryName', component: CitiesComponent },
      { path: 'tour/:countryName/:cityName', component: CityComponent },
      { path: 'tour/:countryName/:cityName/:tourId', component: TourComponent },
      { path: 'tour/:countryName/:cityName/:tourId/reviews', component: TourReviewsComponent },
      { path: 'gettour', component: GetTourComponent },
      { path: 't/:tourId', component: TourComponent },
      { path: 'tour-purchased/:tourId', component: TourPurchasedComponent },
      { path: 'alltours', redirectTo: '' },
      { path: 'map', redirectTo: 'explorer/map' },
      { path: 'tour', redirectTo: 'cities' },
      { path: 'admin-login', component: AdminLoginComponent },
      {
        path: 'user',
        component: UserSectionComponent,
        canActivate: [UserAuthGuard],
        children: [{ path: '', component: UserProfileComponent }],
      },
    ]
  },
  {
    path: 'admin',
    component: AdminComponent,
    canActivate: [AdminAuthGuard],
    children: [
      { path: '', component: AdminDashboardComponent },
      { path: 'feedback', component: FeedbackComponent },
      { path: 'tours', component: AdminToursComponent },
      { path: 'codes', component: AdminRedemptionCodesComponent },
      { path: 'reviews', component: AdminReviewsComponent },
      { path: 'editor/translation/:lang/:tourId', component: AdminEditorComponent },
      { path: 'editor/translation/:lang/:tourId/:landmarkId', component: AdminLandmarkEditorComponent },
      { path: 'editor/:tourId', component: AdminEditorComponent },
      { path: 'editor/:tourId/:landmarkId', component: AdminLandmarkEditorComponent },
    ],
  },
  {
    path: 'partner',
    component: PartnerComponent,
    canActivate: [PartnerAuthGuard],
    children: [
      { path: '', component: PartnerDashboardComponent },
      {
        path: 'marketing',
        component: PartnerMarketingComponent,
        children: [
          { path: '', component: PartnerMarketingDashboardComponent },
          { path: 'poster', component: PartnerPosterEditorComponent },
        ],
      },
      { path: 'statistics', component: PartnerStatisticsComponent },
      { path: 'contact', component: ContactUsComponent },
      { path: 'terms', component: PartnerTermsComponent },
    ],
  },
  {
    path: 'become-a-partner', component: BecomeAPartnerComponent, children: [
      { path: '', component: PartnerAdComponent },
      { path: 'login', component: PartnerLoginComponent },
      { path: 'signup', component: PartnerSignupComponent },
      { path: 'terms', component: PartnerTermsComponent },
    ]
  },
  {
    path: 'explorer',
    component: ExplorerAppComponent,
    children: [
      { path: 'home', component: HomeComponent },
      { path: 'policy', component: PolicyComponent },
      { path: 'map', component: MapComponent },
      { path: 'delete', component: DeleteAccountComponent },
      { path: '', redirectTo: 'home', pathMatch: 'full' },
      { path: '**', redirectTo: 'home' },
    ],
  },
  { path: ':lang/alltours/policy', redirectTo: '/policy' },
  { path: ':lang/alltours/terms', redirectTo: '/terms', pathMatch: 'full' },
  { path: '**', redirectTo: '' },
];

@NgModule({
  imports: [RouterModule.forRoot(appRoutes, { scrollPositionRestoration: 'enabled' })],
  exports: [RouterModule],
})
export class AppRoutingModule { }
