import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-inner-contact-us',
  templateUrl: './inner-contact-us.component.html',
  styleUrls: ['./inner-contact-us.component.css', '../../../app.component.css']
})
export class InnerContactUsComponent {

  constructor(private router: Router) { }

  showWhatsapp = false

  ngOnInit() {
    this.showWhatsapp = this.router.url.includes("partner")
  }

}
