// breadcrumb.service.ts
import { Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';
import { capitalise } from './utils/utils';

@Injectable({
  providedIn: 'root'
})
export class BreadcrumbService {
  private renderer: Renderer2;

  constructor(
    private router: Router,
    rendererFactory: RendererFactory2
  ) {
    this.renderer = rendererFactory.createRenderer(null, null);
  }

  initBreadcrumbs() {
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe(() => {
      const breadcrumbs = this.generateBreadcrumbs(window.location.href);
      this.setJsonLdScript(breadcrumbs, "breadcrumbs");
    });
  }

  private generateBreadcrumbs(url: string): any {
    const urlSegments = new URL(url).pathname.split('/').filter(Boolean);
    return {
      "@context": "https://schema.org",
      "@type": "BreadcrumbList",
      itemListElement: [
        {
          "@type": "ListItem",
          position: 1,
          name: "Home",
          item: `${new URL(url).origin}/`
        },
        ...urlSegments.map((segment, index) => ({
          "@type": "ListItem",
          position: index + 2,
          name: this.getNameFromSegment(segment),
          item: `${url.split('/').slice(0, index + 4).join('/')}/`
        }))
      ]
    };
  }

  private getNameFromSegment(segment: string): string {
    const uuidRegex = /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/i;
    const isUUID = uuidRegex.test(segment);
    if (isUUID) {
      return 'Product';
    }
    return capitalise(segment).replace(/-/g, ' ');
  }

  private setJsonLdScript(data: any, id: string) {
    // Remove existing script tag with the same ID if it exists
    const existingScript = document.querySelector(`script#${id}`);
    if (existingScript) {
      this.renderer.removeChild(document.head, existingScript);
    }

    // Create new script tag with a unique ID
    const script = this.renderer.createElement('script');
    script.id = id;
    script.type = 'application/ld+json';
    script.text = JSON.stringify(data, null, 2);
    this.renderer.appendChild(document.head, script);
    console.log('breadcrumbs', data)
  }

  addProductDetails(product: any) {
    this.setJsonLdScript(product, 'product-details');
  }


}
