<div class="message-container">
  <div class="container">
    <div class="row">
      <div class="message col-12">Redirecting to Tour...</div>
      <div class="col-12">
        <button class="btn ctaBtnMini" (click)="goToAppStore()">Redirect manually</button>
      </div>
    </div>
  </div>
</div>
