import { Component, OnInit } from '@angular/core';
import { tns, TinySliderInstance } from 'tiny-slider/src/tiny-slider';

@Component({
  selector: 'app-reviews',
  templateUrl: './reviews.component.html',
  styleUrls: ['./reviews.component.css'],
})
export class ReviewsComponent implements OnInit {
  slider: TinySliderInstance;
  partners;
  dialog;

  constructor() {}

  ngOnInit() {
    this.dialog = document.getElementById('partnersDialog');
    this.partners = [
      {
        img: '12go.jpg',
        link: 'https://tp.media/click?shmarker=392535&promo_id=1765&source_type=link&type=click&campaign_id=44&trs=195101',
      },
      {
        img: 'omio.jpg',
        link: 'https://omio.sjv.io/c/3663291/1562144/7385',
      },
      {
        img: 'viator.jpg',
        link: 'https://www.viator.com/?pid=P00080684&mcid=42383&medium=link&campaign=website',
      },
      {
        img: 'bookingcom.jpg',
        link: 'https://www.booking.com/?aid=7974855',
      },
      {
        img: 'airalo.jpg',
        link: 'https://tp.media/click?shmarker=392535&promo_id=8405&source_type=link&type=click&campaign_id=541&trs=195101',
      },
      {
        img: 'economybookings.jpg',
        link: 'https://tp.media/click?shmarker=392535&promo_id=2020&source_type=link&type=click&campaign_id=10&trs=195101',
      },
      {
        img: 'ivisa.jpg',
        link: 'https://www.ivisa.com/?utm_source=explorer-app',
      },
      {
        img: 'kiwi.jpg',
        link: 'https://www.kiwi.com/en/',
      },
      {
        img: 'stubhub.jpg',
        link: 'https://www.stubhub.com/',
      },
      {
        img: 'tourradar.jpg',
        link: 'https://www.tourradar.com/',
      },
      {
        img: 'tripcom.jpg',
        link: 'https://tp.media/click?shmarker=392535&promo_id=3758&source_type=link&type=click&campaign_id=121&trs=195101',
      },
      {
        img: 'viagogo.jpg',
        link: 'https://www.viagogo.com/',
      },
      {
        img: 'visahq.jpg',
        link: 'https://www.visahq.com/?a_aid=vaff14238',
      },
      {
        img: '12go.jpg',
        link: 'https://tp.media/click?shmarker=392535&promo_id=1765&source_type=link&type=click&campaign_id=44&trs=195101',
      },
      {
        img: 'omio.jpg',
        link: 'https://omio.sjv.io/c/3663291/1562144/7385',
      },
      {
        img: 'viator.jpg',
        link: 'https://www.viator.com/?pid=P00080684&mcid=42383&medium=link&campaign=website',
      },
      {
        img: 'bookingcom.jpg',
        link: 'https://www.booking.com/?aid=7974855',
      },
      {
        img: 'airalo.jpg',
        link: 'https://tp.media/click?shmarker=392535&promo_id=8405&source_type=link&type=click&campaign_id=541&trs=195101',
      },
      {
        img: 'economybookings.jpg',
        link: 'https://tp.media/click?shmarker=392535&promo_id=2020&source_type=link&type=click&campaign_id=10&trs=195101',
      },
      {
        img: 'ivisa.jpg',
        link: 'https://www.ivisa.com/?utm_source=explorer-app',
      },
      {
        img: 'kiwi.jpg',
        link: 'https://www.kiwi.com/en/',
      },
      {
        img: 'stubhub.jpg',
        link: 'https://www.stubhub.com/',
      },
      {
        img: 'tourradar.jpg',
        link: 'https://www.tourradar.com/',
      },
      {
        img: 'tripcom.jpg',
        link: 'https://tp.media/click?shmarker=392535&promo_id=3758&source_type=link&type=click&campaign_id=121&trs=195101',
      },
      {
        img: 'viagogo.jpg',
        link: 'https://www.viagogo.com/',
      },
      {
        img: 'visahq.jpg',
        link: 'https://www.visahq.com/?a_aid=vaff14238',
      },
    ];
  }

  nextReview() {
    this.slider.goTo('next');
  }

  prevReview() {
    this.slider.goTo('prev');
  }

  showPartnersDialog() {
    this.dialog.showModal();
  }

  closeDialog() {
    this.dialog.close();
  }
}
