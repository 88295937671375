<div style="background: #fefcf4">
  <div style="display: flex">
    <div style="width: 100%">
      <img style="width: 100%; height: 95vh; object-fit: cover" src="../../../assets/images/alltours/city.jpg" />
    </div>
    <div class="imgCover">
      <div class="landingContent">
        <div class="centralMedia">
          <div id="ctaHolder">
            <h1 i18n id="landingTitle">{{ strings.any_city_any_language }}</h1>
            <p i18n id="landingSubtitle">{{ strings.home_sub_title_text }}</p>
            <app-search></app-search>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div style="text-align: center; margin-top: 32px">
    <div style="padding: 16px">
      <h1 class="screenshotTitle">{{ strings.top_tours_title }}</h1>
      <p style="color: #0008">{{ strings.top_tours_description }}</p>
    </div>
    <div class="container" style="text-align: center; width: 100%">
      <div class="row" *ngIf="featuredTours.length == 0">
        <div class="col-12 col-md-6 col-xl-4 tourPreviewOuterContainer" *ngFor="let number of [0,1,2]">
          <app-tour-preview [tour]="undefined"></app-tour-preview>
        </div>
      </div>
      <div class="row" style="place-content: center" [@fadeInUp]="featuredTours.length == 0 ? 'out' : 'in'">
        <div class="col-12 col-md-6 col-xl-4 tourPreviewOuterContainer" *ngFor="let tour of featuredTours"
          style="padding: 0; text-align: -webkit-center">
          <app-tour-preview [tour]="tour"></app-tour-preview>
        </div>
      </div>
    </div>
  </div>
  <div class="container">
    <div *ngFor="let screenshot of screenshots" class="row screenshotRow" style="color: #303f00; max-height: 2000px">
      <div *ngIf="screenshot.id % 2 == 0" class="col-lg-6 col-sm-12 textHolder">
        <div class="textLeft">
          <h1 class="screenshotTitle">{{ screenshot.title }}</h1>
          <p style="color: #0008">{{ screenshot.text }}</p>
        </div>
      </div>
      <div class="col-lg-6 col-sm-12 screenshotHolder">
        <img style="height: 100%; max-width: 100%; object-fit: contain" src="{{ screenshot.img }}" />
      </div>
      <div *ngIf="screenshot.id % 2 == 1" class="col-lg-6 col-sm-12 order-first order-lg-last textHolder">
        <div class="textRight">
          <h1 class="screenshotTitle">{{ screenshot.title }}</h1>
          <p style="color: #0008">{{ screenshot.text }}</p>
        </div>
      </div>
    </div>
    <div class="row" style="padding-top: 48px; padding-bottom: 48px;">
      <div class="col-12 col-md-6" style="text-align: center; margin-bottom: 16px;">
        <img src="../../../assets/images/alltours/appStoreButton.png" (click)="goToAppStore()" />
      </div>
      <div class="col-12 col-md-6" style="text-align: center; margin-bottom: 16px;">
        <img src="../../../assets/images/alltours/googlePlayButton.png" (click)="goToPlayStore()" />
      </div>
    </div>
  </div>
</div>