import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import firebase from 'firebase';
import { UserService } from '../user.service';

@Component({
  selector: 'app-download-app',
  templateUrl: './download-app.component.html',
  styleUrls: ['./download-app.component.css', '../../../app.component.css']
})
export class DownloadAppComponent implements OnInit {

  constructor(private route: ActivatedRoute, private userService: UserService) { }

  @Input() lastStepToShow

  lastStep
  loginText
  isIphone
  storeIcon
  storeQrCode

  ngOnInit(): void {
    if (this.lastStepToShow == 'createTour') {
      this.lastStep = this.lastStepCreateTour;
    } else if (this.lastStepToShow == 'enjoyTour') {
      this.lastStep = this.lastStepEnjoyTour;
    }
    this.isIphone = navigator.userAgent.match(/Mac|iPhone|iPad|iPod/i)
    this.storeIcon = this.isIphone ? 'appstoreicon' : 'playstoreicon';
    this.storeQrCode = this.isIphone ? 'appstoreqrcode' : 'playstoreqrcode';
    this.userService.user$.subscribe(user => {
      this.createLoginText()
    })
  }

  createLoginText() {
    let user = firebase.auth().currentUser
    console.log('is logged in', user != null)
    if (user == null) {
      return
    }
    let providerId = firebase.auth().currentUser.providerData[0].providerId
    let email = firebase.auth().currentUser.email
    console.log('providerId', providerId)
    switch (providerId) {
      case 'google.com':
        this.loginText = `Using Google (${email})`;
        break;
      case 'apple.com':
        this.loginText = `Using Apple (${email})`;
        break;
      default:
        this.loginText = `Using your email (${email})`;
    }
  }

  goToApp() {
    if (this.isIphone) {
      firebase.analytics().logEvent('post_purchase_download_click', { type: 'ios' });
      window.open('https://apps.apple.com/app/alltours-audio-tours/id6475402833?platform=iphone');
    } else {
      firebase.analytics().logEvent('post_purchase_download_click', { type: 'not-ios' });
      window.open('https://play.google.com/store/apps/details?id=app.alltours');
    }
  }

  lastStepCreateTour = {
    icon: "play_circle",
    title: "Create your tour",
    description: "You'll be ready to go in a matter of minutes.",
  }

  lastStepEnjoyTour = {
    icon: "play_circle",
    title: "Enjoy your tour",
    description: "Once logged in, you can start your tour.",
  }

}