import { Component, OnInit } from '@angular/core';
import { AdminAuthService } from './admin-auth-service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.css', '../../app.component.css'],
})
export class AdminComponent implements OnInit {
  constructor(private adminAuthService: AdminAuthService, private router: Router) { }

  ngOnInit() { }

  logout() {
    this.adminAuthService.logout();
    this.router.navigate(['']);
    window.location.reload();
  }
}
