<div id="background1" *ngIf="posterContent != undefined">
  <div style="display: flex; height: 100%; position: absolute; background: white;">
    <img style="width: 100%; height: 100%; object-fit: cover" src="../../../../../../assets/images/alltours/city.jpg" />
    <div class="imgCover" style="height: 100%;"></div>
  </div>
  <div style="position: relative;">
    <div style="position: relative;">
      <img src="../../../../../../assets/images/alltours/logo.png" class="businessIcon" />
      <!-- <img src="../../../../../assets/images/alltours/logo.png" class="businessIcon"
        style="position: absolute; right: 0;" /> -->
      <div class="headersContainer">
        <div class="headerOne">
          Don't just see the city
        </div>
        <div class="headerTwo">
          <i>Understand</i> the city
        </div>
      </div>
    </div>
  </div>

  <div id="lowerHalf">
    <div style="display: flex; padding-top: 64px; padding-bottom: 48px;">
      <div class="contentContainer" style="width: 60%;">
        <div style="font-size: 60px;">
          {{ posterContent.city.name }} Audio Tours from {{ posterContent.price }}
        </div>
        <div style="margin-top: 64px;">
          <span class="infoPoint">Learn about {{ posterContent.city.name }}'s landmarks</span><br />
          <span class="infoPoint">Listen to their stories as you walk the streets</span><br />
          <span class="infoPoint">Uncover the history behind what you see</span>
        </div>
      </div>
      <div *ngIf="posterContent.city != undefined && posterContent.city.marketing != undefined" class="contentContainer"
        style="width: 40%; padding: 64px;">
        <div class="scanToStartText">
          Scan to start exploring
        </div>
        <div class="qrCode">
          <img style="border-radius: 16px;" src="{{ posterContent.city.marketing.qrCode }}" />
        </div>
        <div class="urlText">
          {{ posterContent.city.marketing.link }}
        </div>
      </div>
    </div>
    <div class="posterFooter">
      <div style="display: flex; align-items: center;" class="contentContainer">
        <i class="socialIcon fa fa-instagram"></i>
        <span>&#64;alltours.app</span>
        <div style="width: 1px; background: #fffa; height: 20px; margin-left: 12px; margin-right: 8px;"></div>
        <i class="socialIcon fa fa-envelope"></i>
        <span>kes&#64;alltours.app</span>
      </div>
      <div class="contentContainer" style="width: 100%; text-align: right;">
        <span>
          In partnership with {{ posterContent.partnerName }}
        </span>
      </div>
    </div>
  </div>
</div>

<style>
  .posterFooter {
    text-align: center;
    width: 100%;
    font-weight: 500;
    display: flex;
    bottom: 0;
  }

  .headersContainer {
    width: 100%;
    text-align: center;
    font-size: 58px;
    padding-top: 16px;
    text-transform: uppercase;
  }

  .headerOne {
    padding-top: 48px;
    padding-bottom: 48px;
  }

  .headerTwo {
    padding-top: 48px;
    padding-bottom: 48px;
    color: #daff5e;
    background: #3b4d00;
  }

  .socialIcon {
    margin-left: 8px;
    margin-right: 8px;
    font-size: 18px;
    align-content: center;
  }

  #heroImage {
    position: absolute;
    bottom: -1px;
    right: 0;
    max-width: 95%;
  }

  .contentContainer {
    padding: 48px
  }

  #background1 {
    width: 8.27in;
    height: 11.69in;
    background: #fafafa;
    color: white;
    margin: auto;
    position: relative;
    overflow: hidden;
    font-weight: bold;
    font-family: Roboto, 'Helvetica Neue', sans-serif;
    line-height: 1.1;
  }

  .businessIcon {
    height: 36px;
    width: 36px;
    margin: 24px;
    position: absolute;
  }

  #hookHolder1 {
    text-align: center;
    font-size: 64px;
    padding: 32px;
    font-weight: 700;
    padding-right: 48px;
  }

  #hookHolder2 {
    background: #3b4d00;
    text-align: center;
    font-size: 64px;
    padding: 32px;
    font-weight: 700;
    color: white;
    padding-right: 48px;
  }

  .qrCode {
    background: #fff;
    border-radius: 16px;
    aspect-ratio: 1 / 1;
    margin: auto;
    border: #000 4px solid;
    box-shadow: 0 0 0px 4px #daff5e,
      0 0 0px 8px #000;
  }

  #price {
    font-size: 120px;
  }

  .infoPoint {
    margin-top: 16px;
    font-size: 20px;
    display: block;
  }

  .scanToStartText {
    padding-bottom: 20px;
    text-align: center;
    font-size: 20px;
  }

  .urlText {
    background: #fff;
    border-radius: 16px;
    border: #000 4px solid;
    color: #000;
    padding: 12px;
    font-size: 14px;
    text-align: center;
    margin-top: 16px;
    line-break: anywhere;
  }

  #lowerHalf {
    height: inherit;
    padding: 0px;
    position: relative;
  }

  @media print {
    .bottomButtonContainer {
      display: none;
    }

    #lowerHalf {
      background: #3b4d00;
    }

    @page {
      size: A4;
      margin: 0mm;
    }
  }

  .imgCover {
    background: #00000080;
    width: 100%;
    height: 95vh;
    position: absolute;
    color: white;
  }
</style>