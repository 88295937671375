import firebase from 'firebase';
import { Component, OnInit, Inject } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Router } from '@angular/router';
import stringsJson from './../assets/strings/strings.json';
import { FormsModule } from '@angular/forms';
import { AdminAuthService } from './alltours-app/admin/admin-auth-service';
import { Purchases } from '@revenuecat/purchases-js';
import { UserService } from './alltours-app/user/user.service';
import { PartnerService } from './alltours-app/partner/partner-service';
import { PurchasesService } from './alltours-app/user/purchases.service';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { slideInOutAnimation } from './utils/animations';
import { BreadcrumbService } from './bread-crumb.service';

const firebaseConfig = {
  apiKey: 'AIzaSyCedN1xnkghs3CLmwCm95cICJiEqwPnLW4',
  authDomain: 'explorer-f45e6.firebaseapp.com',
  projectId: 'explorer-f45e6',
  storageBucket: 'explorer-f45e6.appspot.com',
  messagingSenderId: '466547680272',
  appId: '1:466547680272:web:93721e377d2d6e51731695',
  measurementId: 'G-3C4N7SPFPQ',
};

firebase.initializeApp(firebaseConfig);
const analytics = firebase.analytics();

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent {
  todaysDate = Date.now();
  strings;
  language;
  lang;
  isAdmin;
  userType: UserType
  UserType = UserType
  loadingPage = true

  constructor(
    public router: Router,
    @Inject(MatDialog) public dialog: MatDialog,
    private route: ActivatedRoute,
    private adminAuthService: AdminAuthService,
    public userService: UserService,
    public partnerService: PartnerService,
    private breadcrumbService: BreadcrumbService
  ) {
    document.title = 'AllTours';
    console.log('navigator.language', navigator.language);
    this.isAdmin = this.adminAuthService.loggedInAsAdmin();
    this.language = window.location.hostname.split('.')[0];
    if (this.language == undefined || this.language.length > 2) this.language = 'en';
    this.strings = JSON.parse(JSON.stringify(stringsJson))['default'][this.language];
    this.router.routeReuseStrategy.shouldReuseRoute = function () {
      return false;
    };
    const linkElement = document.querySelector('link[rel=icon]');
    linkElement['href'] = '../../assets/images/alltours/favicon.ico';
    this.listenToRouter();
  }

  ngOnInit() {
    this.breadcrumbService.initBreadcrumbs();
  }

  goToApp() {
    if (navigator.userAgent.match(/Mac|iPhone|iPad|iPod/i)) {
      firebase.analytics().logEvent('alltours_cta_click', { type: 'ios' });
      window.open('https://apps.apple.com/app/alltours-audio-tours/id6475402833?platform=iphone');
    } else {
      firebase.analytics().logEvent('alltours_cta_click', { type: 'not-ios' });
      window.open('https://play.google.com/store/apps/details?id=app.alltours');
    }
  }

  listenToRouter() {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        this.loadingPage = true;
      }
      if (
        event instanceof NavigationEnd ||
        event instanceof NavigationCancel ||
        event instanceof NavigationError
      ) {
        this.loadingPage = false;
      }
    });
  }
}


export enum UserType {
  USER,
  ADMIN,
  PARTNER
}
