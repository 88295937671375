<div class="container">
  <div class="row">
    <div class="col-lg-6 offset-lg-3">
      <div class="card" style="margin-top: 20px">
        <div class="card-header">
          <h4>Login</h4>
        </div>
        <div class="card-body">
          <form id="loginForm">
            <div class="mb-3">
              <label for="emailAddress" class="form-label">Email</label>
              <input name="emailAddress" id="emailAddress" type="email" class="form-control" required />
            </div>
            <div class="mb-3">
              <label for="password" class="form-label">Password</label>
              <input name="password" id="password" type="password" class="form-control" maxlength="200" required />
            </div>
            <div class="col-12" style="text-align: center">
              <button *ngIf="!loading" class="ctaBtnMini" type="submit"
                style="width: 100%; margin-top: 16px">Login</button>
              <div *ngIf="loading" style="text-align: center; margin: auto;">
                <div class="loader" style="margin: auto; padding: 8px;"></div>
                Loading...
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>