<h2 style="padding: 1vh; text-align: center">Feedback</h2>
<div class="table-responsive">
  <table class="table table-bordered table-striped">
    <thead class="thead-dark">
      <tr>
        <th scope="col">Date</th>
        <th scope="col" style="min-width: 300px">Message</th>
        <th scope="col">Email</th>
        <th scrope="col">Platform</th>
        <th scope="col" style="min-width: 150px">Tour</th>
        <th scope="col">Tour ID</th>
        <th scope="col" style="min-width: 150px">Landmark</th>
        <th scope="col">User id</th>
        <th scope="col">Images</th>
        <th scope="col">Logs</th>
        <th scope="col">Delete</th>
        <th scope="col">Done</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let feedback of feedbacks" [ngClass]="{ 'table-success': feedback.done == true }">
        <th scope="row">{{ getDate(feedback.date) }}</th>
        <td [innerHtml]="getDescription(feedback)"></td>
        <td>
          <a *ngIf="feedback.email != undefined" href="mailto:{{ feedback.email }}"
            >{{ feedback.email != undefined ? feedback.email.substring(0, 10) : '' }}...</a
          >
        </td>
        <td>{{ feedback.platform }} {{ getPlatformIcon(feedback) }}</td>
        <td>
          {{ feedback.type == 'tour' ? feedback.tour.title : '' }}
          <br />
          <br />
          <a
            *ngIf="feedback.tour != undefined"
            style="color: orange; align-self: flex-end"
            target="_blank"
            href="https://console.firebase.google.com/u/0/project/explorer-f45e6/firestore/databases/-default-/data/~2Fguided-tours/{{
              feedback.tour.tourId
            }}"
            >Firestore</a
          >
        </td>
        <td>{{ feedback.type == 'tour' ? feedback.tour.tourId : '' }}</td>
        <td>
          {{ feedback.type == 'tour' ? feedback.landmark.title + ' (' + feedback.landmark.landmarkId + ')' : '' }}
        </td>
        <td>{{ feedback.userId != undefined ? feedback.userId.substring(0, 10) : '' }}...</td>
        <td (click)="showImages(feedback.imageUrls)">
          <div *ngIf="feedback.imageUrls != undefined" style="display: flex">
            <div *ngFor="let img of feedback.imageUrls.slice(0, 3)">
              <img class="feedback-img" src="{{ img }}" />
            </div>
          </div>
        </td>
        <td (click)="showLogs(feedback.logs)">Show logs</td>
        <td style="color: red" (click)="deleteFeedback(feedback)">Delete</td>
        <td (click)="toggleDoneFeedback(feedback)">
          <i *ngIf="feedback.done" class="fa fa-check-square"></i>
          <i *ngIf="!feedback.done" class="fa fa-square-o"></i>
        </td>
      </tr>
    </tbody>
  </table>
</div>
<div style="text-align: center" *ngIf="!loading">
  <p *ngIf="endOfFeedback" style="text-align: center">End of feedback</p>
  <button *ngIf="!endOfFeedback" class="btn btn-primary" (click)="getFeedback(5)">Load more</button>
</div>
<div style="text-align: center; padding: 64px" *ngIf="loading">
  <span class="sr-only">Loading...</span>
</div>

<dialog id="logModal">
  <div class="modal-dialog" style="max-width: 80%">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="logModalLabel">Logs</h5>
        <button type="button" class="btn btn-secondary" (click)="hideLogs()">Close</button>
      </div>
      <div class="modal-body">
        <ul class="list-group">
          <li *ngFor="let log of selectedLogs" class="list-group-item">{{ log }}</li>
        </ul>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" (click)="hideLogs()">Close</button>
      </div>
    </div>
  </div>
</dialog>

<dialog id="imageModal">
  <div class="modal-dialog modal-lg" style="max-width: 95%">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="logModalLabel">Image Gallery</h5>
        <button type="button" class="btn btn-secondary" (click)="hideImages()">Close</button>
      </div>
      <div class="modal-body">
        <div class="container-fluid">
          <div class="row">
            <div class="col-12">
              <div class="row g-2">
                <div class="col-md-4 col-sm-6 col-12" *ngFor="let image of selectedImages">
                  <img style="max-height: 90vh" class="img-fluid img-thumbnail" src="{{ image }}" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" (click)="hideImages()">Close</button>
      </div>
    </div>
  </div>
</dialog>
