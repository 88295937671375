import { Component, OnInit } from '@angular/core';
import firebase from 'firebase';

@Component({
  selector: 'app-unlimited',
  templateUrl: './unlimited.component.html',
  styleUrls: ['./unlimited.component.css', '../../../app.component.css'],
})
export class UnlimitedComponent implements OnInit {
  landmarks = [];

  constructor() {}

  ngOnInit() {}

  subscribe() {}

  /*
      firebase.firestore().collection("guided-tours")
    .limit(40).get().then((querySnapshot) => {
      querySnapshot.forEach((doc) => {
        if(doc.data().landmarks != undefined){
          let images = doc.data().landmarks.map(landmark => landmark.imageUrl).filter(url => url != undefined)
          this.landmarks.push(...images)
        }
      })
      this.landmarks.sort(() => (Math.random() > 0.5) ? 1 : -1)
      this.landmarks = [...new Set(this.landmarks)]
    })

    <div style="display: block;">
    <div style="background: #1B1C17; display: flex; flex-flow:row wrap;">
        <div *ngFor="let landmark of landmarks" style="display: flex; padding: 4px;">
            <img src="{{landmark}}" style="height: 72px;width: 72px;border-radius: 16px;border: 2px solid #fff;" />
            <div class="playBtn">
                <i class="fa fa-play-circle icons"></i>
            </div>
        </div>
    </div>
</div>
  */
}
