<div class="login-container">
  <div class="row pt-5">
    <div class="col-md-6 mx-auto">
      <div class="card rounded-0">
        <div class="card-header">
          <h3 class="mb-0">Admin Login</h3>
        </div>
        <div class="card-body">
          <div class="form-group">
            <label for="username">Passcode</label>
            <input [(ngModel)]="enteredCode" type="text" class="form-control rounded-0" required />
          </div>

          <button type="button" (click)="loginUser()" class="btn btn-success float-right">Submit</button>
        </div>
      </div>
    </div>
  </div>
</div>
```
