<h2 style="padding: 1vh; text-align: center">Dashboard</h2>
<div class="container">
  <div class="row">
    <div class="col-12">
      <iframe
        width="100%"
        height="600"
        src="https://lookerstudio.google.com/embed/reporting/e33429aa-4bd9-46f0-92fe-9a25e37d72ed/page/kjx1D"
        frameborder="0"
        style="border: 0"
        allowfullscreen
        sandbox="allow-storage-access-by-user-activation allow-scripts allow-same-origin allow-popups allow-popups-to-escape-sandbox"></iframe>
    </div>
  </div>
  <div class="row">
    <div class="col-md-3">
      <a href="https://app.revenuecat.com/overview" target="_blank" class="dashboard-item">
        <div class="rounded-box">
          <i class="fa fa-usd"></i><br />
          <span>RevenueCat</span>
        </div>
      </a>
    </div>
    <div class="col-md-3">
      <a
        href="https://console.firebase.google.com/u/0/project/explorer-f45e6/crashlytics/app/ios:app.alltours/issues?time=last-seven-days&state=open&tag=all&sort=eventCount"
        target="_blank"
        class="dashboard-item">
        <div class="rounded-box">
          <i class="fa fa-cog"></i><br />
          <span>Crashlytics</span>
        </div>
      </a>
    </div>
  </div>
</div>
