export function stringFormat(template: string, ...args: any[]) {
  return template.replace(/{(\d+)}/g, function (match, number) {
    return typeof args[number] != 'undefined' ? args[number] : match;
  });
}

export function capitalise(val) {
  return String(val).charAt(0).toUpperCase() + String(val).slice(1);
}

export function setSeoText(title, description) {
  document.title = title;
  document.head.children.namedItem('description')['content'] = description;
}