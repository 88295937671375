import { BrowserModule, provideClientHydration } from '@angular/platform-browser';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA, ErrorHandler, APP_INITIALIZER } from '@angular/core';
import * as Sentry from "@sentry/angular";

import { AppComponent } from './app.component';
import { ReviewsComponent } from './explorer-app/reviews/reviews.component';
import { PolicyComponent } from './explorer-app/policy/policy.component';
import { HomeComponent } from './explorer-app/home/home.component';
import { AppRoutingModule } from './app-routing.module';
import { MapComponent } from './explorer-app/map/map.component';
import { NgxMapboxGLModule } from 'ngx-mapbox-gl';
import { ScreenshotsComponent } from './explorer-app/screenshots/screenshots.component';
import { AllToursHomeComponent } from './alltours-app/user/home/all-tours-home.component';
import { ExplorerAppComponent } from './explorer-app/explorer-app.component';
import { CitiesComponent } from './alltours-app/user/cities/cities.component';
import { CityComponent } from './alltours-app/user/city/city.component';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatDialogModule } from '@angular/material/dialog';
import { TermsComponent } from './alltours-app/user/terms/terms.component';
import { TourPreviewComponent } from './alltours-app/user/tour-preview/tour-preview.component';
import { TourComponent } from './alltours-app/user/tour/tour.component';
import { TourReviewComponent } from './alltours-app/user/tour-review/tour-review.component';
import { TourReviewsComponent } from './alltours-app/user/tour-reviews/tour-reviews.component';
import { GetTourComponent } from './alltours-app/user/get-tour/get-tour.component';
import { ContactUsComponent } from './alltours-app/user/contact-us/contact-us.component';
import { FormatTime } from './utils/pipes/format-time-pipe';
import { ProductComponent } from './alltours-app/admin/product/product.component';
import axios from 'axios';
import { FormsModule } from '@angular/forms';
import { DeleteAccountComponent } from './alltours-app/user/delete-account/delete-account.component';
import { UnlimitedComponent } from './alltours-app/user/unlimited/unlimited.component';
import { BetaComponent } from './alltours-app/user/beta/beta.component';
import { AdminComponent } from './alltours-app/admin/admin.component';
import { AdminLoginComponent } from './alltours-app/admin/admin-login/admin-login.component';
import { AdminAuthGuard, AdminAuthService } from './alltours-app/admin/admin-auth-service';
import { FeedbackComponent } from './alltours-app/admin/feedback/feedback.component';
import { AdminDashboardComponent } from './alltours-app/admin/admin-dashboard/admin-dashboard.component';
import { AdminToursComponent } from './alltours-app/admin/admin-tours/admin-tours.component';
import { AdminEditorComponent } from './alltours-app/admin/admin-editor/admin-editor.component';
import { AdminLandmarkEditorComponent } from './alltours-app/admin/admin-editor/admin-landmark-editor/admin-landmark-editor.component';
import { AdminRedemptionCodesComponent } from './alltours-app/admin/admin-redemption-codes/admin-redemption-codes.component';
import { AdminReviewsComponent } from './alltours-app/admin/admin-reviews/admin-reviews.component';
import { PartnerComponent } from './alltours-app/partner/partner.component';
import { PartnerLoginComponent } from './alltours-app/partner/become-a-partner/partner-login/partner-login.component';
import { PartnerSignupComponent } from './alltours-app/partner/become-a-partner/partner-signup/partner-signup.component';
import { PartnerDashboardComponent } from './alltours-app/partner/partner-dashboard/partner-dashboard.component';
import { BecomeAPartnerComponent } from './alltours-app/partner/become-a-partner/become-a-partner.component';
import { PartnerAuthGuard, PartnerService } from './alltours-app/partner/partner-service';
import { PartnerMarketingComponent } from './alltours-app/partner/partner-marketing/partner-marketing.component';
import { PartnerStatisticsComponent } from './alltours-app/partner/partner-statistics/partner-statistics.component';
import { PartnerPosterComponent1 } from './alltours-app/partner/partner-marketing/partner-poster-editor/partner-poster/partner-poster.component';
import { PartnerMarketingDashboardComponent } from './alltours-app/partner/partner-marketing/partner-marketing-dashboard/partner-marketing-dashboard.component';
import { PartnerTermsComponent } from './alltours-app/partner/partner-terms/partner-terms.component';
import { TourPurchasedComponent } from './alltours-app/user/tour-purchased/tour-purchased.component';
import { UserLoginComponent } from './alltours-app/user/user-login/user-login.component';
import { UserProfileComponent } from './alltours-app/user/user-section/user-profile/user-profile.component';
import { UserSectionComponent } from './alltours-app/user/user-section/user-section.component';
import { UserAuthGuard } from './alltours-app/user/user.service';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { ReactiveFormsModule } from '@angular/forms';
import { UserComponent } from './alltours-app/user/user.component';
import { PartnerAdComponent } from './alltours-app/partner/become-a-partner/partner-ad/partner-ad.component';
import { PartnerPosterEditorComponent } from './alltours-app/partner/partner-marketing/partner-poster-editor/partner-poster-editor.component';
import { InnerContactUsComponent } from './alltours-app/user/inner-contact-us/inner-contact-us.component';
import { SearchComponent } from './alltours-app/user/search/search.component';
import { PartnerPosterComponent2 } from './alltours-app/partner/partner-marketing/partner-poster-editor/partner-poster-2/partner-poster-2.component';
import { Router } from '@angular/router';
import { LandmarkMapComponent } from './alltours-app/user/landmark-map/landmark-map.component';
import { DownloadAppComponent } from './alltours-app/user/download-app/download-app.component';

@NgModule({
  exports: [MatDialogModule],
  declarations: [
    AppComponent,
    ReviewsComponent,
    PolicyComponent,
    HomeComponent,
    MapComponent,
    ScreenshotsComponent,
    AllToursHomeComponent,
    ExplorerAppComponent,
    CitiesComponent,
    CityComponent,
    TermsComponent,
    TourPreviewComponent,
    TourComponent,
    TourReviewComponent,
    TourReviewsComponent,
    GetTourComponent,
    ContactUsComponent,
    FormatTime,
    ProductComponent,
    DeleteAccountComponent,
    UnlimitedComponent,
    BetaComponent,
    AdminComponent,
    AdminLoginComponent,
    FeedbackComponent,
    AdminDashboardComponent,
    AdminToursComponent,
    AdminEditorComponent,
    AdminLandmarkEditorComponent,
    AdminRedemptionCodesComponent,
    AdminReviewsComponent,
    PartnerComponent,
    PartnerLoginComponent,
    PartnerSignupComponent,
    PartnerDashboardComponent,
    BecomeAPartnerComponent,
    PartnerMarketingComponent,
    PartnerStatisticsComponent,
    PartnerPosterComponent1,
    PartnerPosterComponent2,
    PartnerMarketingDashboardComponent,
    PartnerTermsComponent,
    TourPurchasedComponent,
    UserLoginComponent,
    UserProfileComponent,
    UserSectionComponent,
    UserComponent,
    PartnerAdComponent,
    PartnerPosterEditorComponent,
    InnerContactUsComponent,
    SearchComponent,
    LandmarkMapComponent,
    DownloadAppComponent
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  imports: [
    BrowserModule,
    AppRoutingModule,
    NgxMapboxGLModule.withConfig({
      accessToken:
        'pk.eyJ1Ijoia2VzLWF0LWV4cGxvcmVyIiwiYSI6ImNsYWY5MjMzejEyZWczb2xkaG55M25zZG4ifQ.TV4Rb-vPMqZzP1XFA6cCGw',
    }),
    BrowserAnimationsModule,
    FormsModule,
    MatAutocompleteModule,
    MatFormFieldModule,
    MatInputModule,
    ReactiveFormsModule
  ],
  providers: [
    AdminAuthGuard,
    AdminAuthService,
    PartnerService,
    PartnerAuthGuard,
    UserAuthGuard,
    provideHttpClient(withInterceptorsFromDi()),
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler(),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => { },
      deps: [Sentry.TraceService],
      multi: true,
    },
    provideClientHydration(),
  ],
})
export class AppModule { }
