import { Component, OnInit } from '@angular/core';
import { PartnerService } from '../partner-service';
import { LocalStorageService, StorageKey } from 'src/app/local-storage.service';

@Component({
  selector: 'app-partner-dashboard',
  templateUrl: './partner-dashboard.component.html',
  styleUrls: ['./partner-dashboard.component.css', '../../../app.component.css'],
})
export class PartnerDashboardComponent implements OnInit {

  printedPosterOnce
  hasClickedDownloadApp

  constructor(public partnerService: PartnerService, private storageService: LocalStorageService) {
    this.hasClickedDownloadApp = this.storageService.getItem(StorageKey.HAS_CLICKED_DOWNLOAD_APP) != undefined
    this.printedPosterOnce = this.storageService.getItem(StorageKey.PARTNERS_PRINTED_ONCE) != undefined
  }

  ngOnInit() {
    this.getStripeAccount();
  }

  async getStripeAccount() {
    await this.partnerService.loadStripeLink()
  }

  goToAppStore() {
    this.storageService.setItem(StorageKey.HAS_CLICKED_DOWNLOAD_APP, true)
    if (navigator.userAgent.match(/Mac|iPhone|iPad|iPod/i)) {
      window.location.href = `https://apps.apple.com/app/alltours-audio-tours/id6475402833?platform=iphone`;
    } else {
      window.location.href = `https://play.google.com/store/apps/details?id=app.alltours`;
    }
  }

}
