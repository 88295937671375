import { Component, OnInit } from '@angular/core';
import firebase from 'firebase';
const db = firebase.firestore();

@Component({
  selector: 'app-admin-redemption-codes',
  templateUrl: './admin-redemption-codes.component.html',
  styleUrls: ['./admin-redemption-codes.component.css'],
})
export class AdminRedemptionCodesComponent implements OnInit {
  tourCode;
  unlimitedCode;
  tourId;
  unlimitedDays;

  tourCodes;
  unlimitedCodes;

  creatingUnlimitedCode = false;
  creatingTourCode = false;

  constructor() {}

  ngOnInit() {
    this.listenToCodeDocs();
  }

  listenToCodeDocs() {
    db.collection('misc')
      .doc('redemption-codes')
      .onSnapshot(doc => {
        this.tourCodes = doc.data().codes;
        console.log('Current data: ', doc.data().codes);
      });
    db.collection('misc')
      .doc('redemption-codes-unlimited')
      .onSnapshot(doc => {
        this.unlimitedCodes = doc.data().codes;
        console.log('Current data: ', doc.data().codes);
      });
  }

  createTourCode() {
    this.creatingTourCode = true;
    firebase
      .functions()
      .httpsCallable('createRedemptionCodeV2')({ tourId: this.tourId, code: this.tourCode })
      .then(result => {
        console.log('Code created: ' + JSON.stringify(result));
        this.tourCode = result.data;
        this.creatingTourCode = false;
      });
  }

  createUnlimitedCode() {
    this.creatingUnlimitedCode = true;
    firebase
      .functions()
      .httpsCallable('createUnlimitedRedemptionCode')({ code: this.unlimitedCode, days: this.unlimitedDays })
      .then(result => {
        console.log('Code created: ' + result);
        this.unlimitedCode = result.data.code;
        this.creatingUnlimitedCode = false;
      });
  }

  deleteTourCode(code) {}

  deleteUnlimitedCode(code) {}

  async deleteCode(code, unlimited) {
    const docRef = firebase
      .firestore()
      .collection('misc')
      .doc(unlimited ? 'redemption-codes-unlimited' : 'redemption-codes');
    const codes = (await docRef.get()).data().codes;
    const toDelete = codes.find(c => c.code == code);
    if (toDelete != undefined) {
      docRef.update({ codes: firebase.firestore.FieldValue.arrayRemove(toDelete) });
    }
  }
}
