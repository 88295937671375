import { Component, OnInit } from '@angular/core';
import firebase from 'firebase';

@Component({
  selector: 'app-feedback',
  templateUrl: './feedback.component.html',
  styleUrls: ['./feedback.component.css'],
})
export class FeedbackComponent implements OnInit {
  feedbacks = [];
  logDialog;
  imageDialog;
  selectedLogs = [];
  selectedImages = [];
  startAtDoc;
  endOfFeedback = false;
  loading = false;

  constructor() {}

  ngOnInit() {
    this.logDialog = document.getElementById('logModal');
    this.imageDialog = document.getElementById('imageModal');
    this.feedbacks = [];
    this.getFeedback(5);
  }

  getFeedback(limit: number) {
    this.loading = true;
    var query = firebase.firestore().collection('admin/feedback/list').orderBy('date', 'desc').limit(limit);

    if (this.startAtDoc != undefined) {
      query = firebase
        .firestore()
        .collection('admin/feedback/list')
        .orderBy('date', 'desc')
        .startAfter(this.startAtDoc)
        .limit(limit);
    }

    query
      .get()
      .then(querySnapshot => {
        this.endOfFeedback = querySnapshot.docs.length < limit;
        querySnapshot.docs.forEach(doc => {
          this.feedbacks.push(doc.data());
        });

        this.startAtDoc = querySnapshot.docs[querySnapshot.docs.length - 1];

        console.log('feedbacks', this.feedbacks);
        this.loading = false;
      })
      .catch(error => {
        console.log('Error getting documents: ', error);
        this.loading = false;
      });
  }

  deleteFeedback(feedback) {
    this.feedbacks = this.feedbacks.filter(f => f.id != feedback.id);
    firebase
      .firestore()
      .collection('admin/feedback/list')
      .doc(feedback.id)
      .delete()
      .then(() => {
        console.log('Document successfully deleted!');
      })
      .catch(error => {
        console.error('Error removing document: ', error);
      });
  }

  toggleDoneFeedback(feedback) {
    feedback.done = !feedback.done;
    firebase
      .firestore()
      .collection('admin/feedback/list')
      .doc(feedback.id)
      .update({ done: feedback.done })
      .then(() => {
        console.log('Document successfully updated!');
      })
      .catch(error => {
        console.error('Error updating document: ', error);
      });
  }

  getDescription(feedback) {
    if (feedback.type == 'tour') {
      return feedback.issues.map(i => '<b>' + i.id + '</b>: ' + i.description).join('<br/><br/>');
    } else {
      return feedback.message;
    }
  }

  getPlatformIcon(feedback) {
    if (feedback.platform == 'ios') {
      return '🍏';
    } else if (feedback.platform == 'android') {
      return '🤖';
    }
  }

  getDate(milliseconds) {
    return new Date(milliseconds).toLocaleString();
  }

  showLogs(logs) {
    this.selectedLogs = logs.toReversed();
    this.logDialog.showModal();
  }

  hideLogs() {
    this.logDialog.close();
  }

  showImages(images) {
    this.selectedImages = images;
    this.imageDialog.showModal();
  }

  hideImages() {
    this.imageDialog.close();
  }
}
