import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable()
export class AdminAuthService {
  constructor() {}

  loggedInAsAdmin() {
    let hasToken = false;
    if (localStorage.getItem('adminToken') == 'secretAdminToken') {
      hasToken = true;
    }
    return hasToken;
  }

  getToken() {
    return localStorage.getItem('adminToken');
  }

  logout() {
    localStorage.removeItem('adminToken');
  }
}

@Injectable()
export class AdminAuthGuard {
  constructor(
    private _authService: AdminAuthService,
    private router: Router
  ) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    if (this._authService.loggedInAsAdmin()) {
      return true;
    } else {
      this.router.navigate(['/admin-login'], { queryParams: { returnUrl: state.url } });
      return false;
    }
  }
}
