<div style="max-width: 500px; margin: auto;">
  <div style="display: flex;" (click)="goToApp()" class="instructionRow highlightedRow">
    <img src="../../../../assets/images/alltours/{{storeIcon}}.png" class="purchasedImage" />
    <div>
      <b class="primaryText">Download the AllTours App</b>
      <p class="secondaryText">It shouldn't take long.</p>
      <img id="storeQrCode" src="../../../../assets/images/alltours/{{storeQrCode}}.png" />
    </div>
    <div class="material-symbols-rounded icon"
      style="margin-left: auto; font-size: 16px; align-content: center; padding: 12px; color: #00f;">
      arrow_forward_ios</div>
  </div>
  <div style="display: flex;" class="instructionRow" *ngIf="loginText != undefined">
    <div class="material-symbols-rounded icon purchasedImage">account_circle</div>
    <div>
      <b class="primaryText">Login with the same account</b>
      <p class="secondaryText">{{ loginText }}</p>
    </div>
  </div>
  <div style="display: flex;" class="instructionRow" *ngIf="lastStep != undefined">
    <div class="material-symbols-rounded icon purchasedImage">{{ lastStep.icon }}</div>
    <div>
      <b class="primaryText">{{ lastStep.title }}</b>
      <p class="secondaryText">{{ lastStep.description }}</p>
    </div>
  </div>
</div>