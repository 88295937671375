import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import firebase from 'firebase';

@Component({
  selector: 'app-get-tour',
  templateUrl: './get-tour.component.html',
  styleUrls: ['./get-tour.component.css', '../../../app.component.css'],
})
export class GetTourComponent implements OnInit {
  tourId;

  constructor(private route: ActivatedRoute) { }

  ngOnInit() {
    this.tourId = this.route.snapshot.queryParamMap.get('tourId');
    this.goToAppStore();
  }

  goToAppStore() {
    if (navigator.userAgent.match(/Mac|iPhone|iPad|iPod/i)) {
      window.location.href = `https://apps.apple.com/app/alltours-audio-tours/id6475402833?platform=iphone`;
    } else {
      window.location.href = `https://play.google.com/store/apps/details?id=app.alltours&tourId=${this.tourId}`;
    }
  }
}
