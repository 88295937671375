import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import firebase from 'firebase';
import { UserService } from '../user.service';

@Component({
  selector: 'app-tour-purchased',
  templateUrl: './tour-purchased.component.html',
  styleUrls: ['./tour-purchased.component.css', '../../../app.component.css'],
})
export class TourPurchasedComponent implements OnInit {
  tourId;
  tour;
  tourTitle = "<br/><br/>";
  language;
  purchased = false;
  showDownloadApp = false
  isIphone
  storeIcon
  storeQrCode
  loginText
  isPotentialTour = false

  constructor(private route: ActivatedRoute, private userService: UserService) { }

  ngOnInit() {
    this.language = window.location.hostname.split('.')[0];
    if (this.language == undefined || this.language.length > 2) this.language = 'en';
    this.tourId = this.route.snapshot.paramMap.get('tourId');
    this.isPotentialTour = this.tourId.startsWith("p-")
    this.getTour();
    this.listenForPurchase();
    console.log("userAgent", navigator.userAgent)
    this.isIphone = navigator.userAgent.match(/Mac|iPhone|iPad|iPod/i)
    this.storeIcon = this.isIphone ? 'appstoreicon' : 'playstoreicon';
    this.storeQrCode = this.isIphone ? 'appstoreqrcode' : 'playstoreqrcode';
    this.userService.user$.subscribe(user => {
      if (user == undefined) return;
      this.createLoginText()
    })
    this.createLoginText()
  }

  getTour() {
    firebase
      .firestore()
      .collection(this.isPotentialTour ? 'potential-tours' : 'guided-tours')
      .where('id', '==', this.tourId)
      .get()
      .then(querySnapshot => {
        this.tour = querySnapshot.docs[0].data();
        this.tourTitle = this.tour.title;
        console.log('tour', this.tour);
      });
  }

  listenForPurchase() {
    this.userService.user$.subscribe(user => {
      if (user == undefined) return;
      if (user.purchasedTourIds.includes(this.tourId)) {
        this.purchased = true
        setTimeout(() => {
          this.showDownloadApp = true
        }, 1000);
        console.log('Purchase fulfilled, id added to user doc')
      } else {
        console.log('Purchase not yet fulfilled, id not in user doc')
      }
    });
  }

  goToApp() {
    if (this.isIphone) {
      firebase.analytics().logEvent('post_purchase_download_click', { type: 'ios' });
      window.open('https://apps.apple.com/app/alltours-audio-tours/id6475402833?platform=iphone');
    } else {
      firebase.analytics().logEvent('post_purchase_download_click', { type: 'not-ios' });
      window.open('https://play.google.com/store/apps/details?id=app.alltours');
    }
  }

  createLoginText() {
    let user = firebase.auth().currentUser
    if (user == null) {
      this.loginText = 'With the credentials you just used.'
      return
    }
    let providerId = firebase.auth().currentUser.providerData[0].providerId
    let email = firebase.auth().currentUser.email
    console.log('providerId', providerId)
    switch (providerId) {
      case 'google.com':
        this.loginText = `Using Google (${email})`;
        break;
      case 'apple.com':
        this.loginText = `Using Apple (${email})`;
        break;
      default:
        this.loginText = `Using your email (${email})`;
    }
  }

}
