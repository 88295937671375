<div>
  <nav style="box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2)"
    class="navbar sticky-top navbar-expand-lg navbar-light bg-light">
    <div class="container-fluid">
      <a class="navbar-brand" routerLink="">
        <div style="display: flex">
          <img id="landingIcon" src="../../assets/images/alltours/logo.png" />
          <span id="landingText">AllTours</span>
        </div>
      </a>
      <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav"
        aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>

      <div class="collapse navbar-collapse" id="navbarNav">
        <ul class="navbar-nav">
          <li class="nav-item">
            <a class="nav-link" routerLink="/admin">Dashboard</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" routerLink="/admin/tours">Tours</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" routerLink="/admin/feedback">Feedback</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" routerLink="/admin/reviews">Reviews</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" routerLink="/admin/codes">Codes</a>
          </li>
        </ul>
        <ul class="navbar-nav">
          <li class="nav-item">
            <a class="nav-link" (click)="logout()">Logout</a>
          </li>
        </ul>
      </div>

    </div>
  </nav>
  <div style="color: #000; background-color: #fff; min-height: 95vh">
    <router-outlet></router-outlet>
  </div>
</div>