<div style="background: #fefcf4; color: #000000aa">
  <div style="text-align: center; padding-top: 32px" class="container">
    <h3 (click)="showPartnersDialog()">Our Partners</h3>
    <p class="description">
      The companies we work with enable us to provide the all-encompassing exploration experience that our app is known
      for.
    </p>
  </div>
  <div class="slider-holder">
    <div class="slider">
      <div class="slide-track">
        <div *ngFor="let partner of partners; index as i">
          <div class="slide">
            <a href="{{ partner.link }}" target="_blank"
              ><img src="..\assets\images\explorer\partners\{{ partner.img }}" height="100" width="250"
            /></a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<dialog id="partnersDialog">
  <div>
    <div>
      <div class="modal-header">
        <h2 class="modal-title">Partners</h2>
      </div>
      <div class="modal-body">
        <div *ngFor="let partner of partners; index as i">
          <div class="row">
            <a href="{{ partner.link }}" target="_blank"
              ><img src="..\assets\images\explorer\partners\{{ partner.img }}" height="100" width="250"
            /></a>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-light" (click)="closeDialog()">Close</button>
      </div>
    </div>
  </div>
</dialog>
