<div *ngIf="features == undefined">
  <div class="mapDisplay shimmer" style="width: 100%;">

  </div>
</div>
<div *ngIf="features != undefined" style="padding-left: 0px; padding-right: 0px">
  <mgl-map class="mapDisplay" [style]="'mapbox://styles/kes-at-explorer/cldkmxdfq007m01lfqiqbiskp'"
    [interactive]="false" [pitchWithRotate]="false" [zoom]="[1]" [center]="center" [maxBounds]="bounds">
    <mgl-marker *ngFor="let feature of features; let i = index" [feature]="feature">
      <div class="landmark" (click)="onLandmarkClick(feature.properties.landmark)">
        <img *ngIf="feature.properties.landmark != undefined" src="{{ feature.properties.landmark.thumbnail }}"
          onerror="this.onerror=null; this.src='../../../assets/images/alltours/landmark_marker.svg'"
          class="landmarkImage" />
      </div>
    </mgl-marker>
  </mgl-map>
</div>