<div *ngIf="tour == undefined" class="row cardContainer">
  <div class="col-4 col-md-12 tourImageHolder">
    <img src="../../../../assets/images/alltours/grey_rectangle.png" class="tourImage shimmer" style="width: 100%;" />
  </div>
  <div class="col-8 col-md-12">
    <div class="previewContainer">
      <p class="shimmer tourTitle">Example title Example title Example title Example title</p>
      <div style="margin: 8px;" class="shimmer">
        Example rating
      </div>
      <div class="tourDescription">
        <div style="width: 100%;">
          <div class="shimmer">
            Example Example
          </div>
          <div class="shimmer" style="margin-top: 8px;">
            Example Example
          </div>
        </div>
      </div>
      <div class="shimmer tourPrice" style="position: absolute;">$2.999</div>
    </div>
  </div>
</div>

<div *ngIf="tour != undefined" class="row cardContainer">
  <div class="col-4 col-md-12 tourImageHolder">
    <img src="{{ tour.image != undefined ? tour.image.medium : '' }}" class="tourImage"
      alt="Main image for tour: {{ tourTitle }}"
      onerror="this.onerror=null; this.src='../../../assets/images/alltours/placeholder.png'"
      routerLink="/tour/{{ tour.city.countryName.toLowerCase() }}/{{ tour.city.name.toLowerCase() }}/{{tour.id}}" />
    <div class="playBtn" *ngIf="!isPotentialTour">
      <i *ngIf="playingState == 0" (click)="playAudio()" class="fa fa-play icons innerPlayBtn"></i>
      <div *ngIf="playingState == 1" class="loaderHolder innerPlayBtn">
        <div class="loader"></div>
      </div>
      <i *ngIf="playingState == 2" (click)="playAudio()" class="fa fa-stop icons innerPlayBtn"></i>
    </div>
  </div>
  <div class="col-8 col-md-12 outerPreviewContainer"
    routerLink="/tour/{{ tour.city.countryName.toLowerCase() }}/{{ tour.city.name.toLowerCase() }}/{{tour.id}}">
    <div class="previewContainer">
      <p class="tourTitle">{{tourTitle}}</p>
      <div class="tourRating"
        *ngIf="tour.ratings != undefined && tour.ratings.ratingCount > 0 && tour.ratings.ratingAvg > 1">
        <div style="color: #ccc;">
          <span class="fa fa-star" [ngClass]="{'checked': tour.ratings.ratingAvg >= 0.5}"></span>
          <span class="fa fa-star" [ngClass]="{'checked': tour.ratings.ratingAvg >= 1.5}"></span>
          <span class="fa fa-star" [ngClass]="{'checked': tour.ratings.ratingAvg >= 2.5}"></span>
          <span class="fa fa-star" [ngClass]="{'checked': tour.ratings.ratingAvg >= 3.5}"></span>
          <span class="fa fa-star" [ngClass]="{'checked': tour.ratings.ratingAvg >= 4.5}"></span>
        </div>
        <div style="margin-left: 4px;">
          <span class="tourRatingCount">{{tour.ratings.ratingCount}}</span>
        </div>
      </div>
      <div class="tourDescription">
        <div style="width: 100%;">
          <div *ngIf="tour.totalTourDuration != undefined">
            <i class="fa fa-clock-o infoIcon"></i>
            <span>{{((tour.totalTourDuration) / 60) | number: '1.0-0'}} minutes</span>
          </div>
          <div style="margin-top: 8px;">
            <i class="fa fa-map-marker infoIcon"></i>
            <span>{{(tour.routeInstructions.distance / 1000) | number: '1.1-1'}} km walk</span>
          </div>
        </div>
      </div>
      <div *ngIf="!tour.purchased && localPrice != undefined && !hasPurchasedTour" class="tourPrice">
        {{localPrice.price}}</div>
      <div *ngIf="hasPurchasedTour" class="tourPrice">Purchased</div>
    </div>
  </div>
</div>