import { AfterViewInit, Component, ContentChild, OnInit, ViewChild } from '@angular/core';
import { LngLatBounds, Map } from 'mapbox-gl';
import firebase from 'firebase';
import { ActivatedRoute } from '@angular/router';

const db = firebase.firestore();

@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.css'],
})
export class MapComponent implements OnInit {
  line;
  stops;
  trip;
  selectedStop;
  id;
  completedLine = [];
  error = false;
  bounds;

  constructor(private route: ActivatedRoute) {}

  ngOnInit() {
    this.id = this.route.snapshot.queryParamMap.get('trip');
    if (this.id !== undefined) {
      this.getDoc();
      this.setRoute();
    }
  }

  async getDoc() {
    this.trip = (await db.collection('shared').doc(this.id).get()).data();
    console.log(this.trip);
    this.error = this.trip === undefined;
    this.setRoute();
  }

  setRoute() {
    this.stops = {
      type: 'FeatureCollection',
      features: this.trip.stops.map(stop => ({
        type: 'Feature',
        properties: {
          stop: stop,
          complete:
            this.trip.shareProgress && stop.endDate !== null && stop.endDate.seconds < Date.now().valueOf() / 1000,
          inProgress:
            this.trip.shareProgress &&
            stop.endDate !== null &&
            stop.startDate.seconds < Date.now().valueOf() / 1000 &&
            stop.endDate.seconds > Date.now().valueOf() / 1000,
        },
        geometry: {
          type: 'Point',
          coordinates: [stop.latLng.longitude, stop.latLng.latitude],
        },
      })),
    };
    this.line = this.stops.features.map(x => x.geometry.coordinates);

    if (this.trip.shareProgress && this.trip.startDate !== null) {
      for (let stop of this.trip.stops) {
        if (stop.startDate.seconds < Date.now().valueOf() / 1000) {
          this.completedLine.push([stop.latLng.longitude, stop.latLng.latitude]);
        }
      }
    }
    this.bounds = this.line.reduce(
      (bounds, coord) => {
        return bounds.extend(<any>coord);
      },
      new LngLatBounds(this.line[0], this.line[0])
    );
  }
}
