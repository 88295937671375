import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import firebase from 'firebase';
import { debounceTime, distinctUntilChanged, filter, map, switchMap, tap } from 'rxjs/operators';
import { fromEvent } from 'rxjs';
import { fadeAnimation } from 'src/app/utils/animations';
import { AllToursFunction, runFunction } from 'src/app/utils/functions';
import citiesJson from '../../../../assets/json/mini_cities.json';
import countriesJson from '../../../../assets/json/countries.json';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrl: './search.component.css',
  animations: [fadeAnimation]
})
export class SearchComponent implements OnInit {
  @ViewChild('searchInput', { static: true }) searchInputRef!: ElementRef;

  results = []
  cities = citiesJson.cities
  searchDebounced
  loading = false
  query = ''

  constructor(private router: Router) {
  }

  ngOnInit() {
    this.setupSearch()
  }

  setupSearch() {
    const searchInput = this.searchInputRef.nativeElement // document.getElementById('searchInput');

    console.log("searchInput", searchInput)

    fromEvent(searchInput, 'input')
      .pipe(
        map((event: any) => {
          this.results = []
          this.query = event.target.value.length
          this.loading = event.target.value.length > 2
          return event.target.value
        }),
        distinctUntilChanged(),
        filter(query => query.length > 2),
      )
      .subscribe(query => {
        console.log("Searching for ", query)
        this.results = this.searchCities(query);
        this.results = this.results.concat(this.searchCountries(query));
      });

    this.searchDebounced = fromEvent(searchInput, 'input')
      .pipe(
        map((event: any) => event.target.value),
        debounceTime(500),
        distinctUntilChanged(),
        filter(query => query.length > 2)
      );

    this.searchDebounced.subscribe(query => {
      this.search(query);
    });
  }

  async search(query) {
    let tours = await runFunction(AllToursFunction.searchAll, { query: query, notCities: true })
    this.results = this.results.concat(tours)
    this.loading = false
  }

  searchCities = (query) => {
    let lowerCaseQuery = query.toLowerCase()
    let filteredCities = this.cities.filter(city => city.name.toLowerCase().includes(lowerCaseQuery))
    return this.sortCitiesByQuery(filteredCities, lowerCaseQuery).slice(0, 3).map(city => ({
      id: city.id.toString(),
      title: city.name,
      description: city.country,
      path: `tour/${city.country.toLowerCase()}/${city.name.toLowerCase()}`,
      type: "city"
    }))
  }

  searchCountries = (query) => {
    let lowerCaseQuery = query.toLowerCase()
    let filteredCountries = Object.values(countriesJson).filter(country => country.en.toLowerCase().includes(lowerCaseQuery))
    return filteredCountries.map(country => ({
      id: country.id.toString(),
      title: country.en,
      path: `tour/${country.en.toLowerCase()}`,
      type: "country"
    }))
  }

  sortCitiesByQuery(cities, lowerCaseQuery) {
    cities.sort((cityA, cityB) => {
      const distanceA = this.levenshteinDistance(lowerCaseQuery, cityA.name.toLowerCase());
      const distanceB = this.levenshteinDistance(lowerCaseQuery, cityB.name.toLowerCase());

      if (distanceA < distanceB) return -1;
      if (distanceA > distanceB) return 1;

      return cityA.importance < cityB.importance ? -1 : 1;
    });

    return cities;
  }

  levenshteinDistance(str1, str2) {
    if (str1 === str2) {
      return 0;
    }

    const [s1, s2] = [str1, str2].sort((a, b) => b.length - a.length);
    const dp = [];

    for (let i = 0; i <= s1.length; i++) {
      dp[i] = new Array(s2.length + 1).fill(i);
    }

    for (let i = 1; i <= s2.length; i++) {
      dp[0][i] = i;
    }

    for (let i = 1; i <= s1.length; i++) {
      for (let j = 1; j <= s2.length; j++) {
        dp[i][j] = Math.min(dp[i - 1][j] + 1, dp[i][j - 1] + 1, dp[i - 1][j - 1] + (s1[i - 1] === s2[j - 1] ? 0 : 1));
      }
    }

    return dp[s1.length][s2.length];
  }

}


