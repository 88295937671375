<h2 style="padding: 1vh; text-align: center">Tour Editor</h2>
<div class="table-responsive">
  <table class="table table-bordered">
    <thead class="thead-dark">
      <tr>
        <th scope="col">Key</th>
        <th scope="col" style="min-width: 200px">Value</th>
      </tr>
    </thead>
    <tbody *ngIf="tour != undefined">
      <tr>
        <th scope="row">Title</th>
        <td><input [(ngModel)]="tour.title" class="form-control" /></td>
      </tr>
      <tr>
        <th scope="row">Landmarks</th>
        <td>
          <table class="table table-bordered">
            <thead class="thead-dark">
              <tr>
                <th scope="col">Title</th>
                <th scope="col">Description</th>
                <th scope="col">Thumbnail</th>
                <th scope="col">Duration</th>
                <th scope="col">Audio</th>
                <th scope="col">Edit</th>
              </tr>
            </thead>
            <tbody *ngIf="tour != undefined">
              <tr
                *ngFor="let landmark of tour.landmarks; let i = index"
                [ngClass]="{ 'table-info': landmark.latLng == undefined }">
                <td><input [(ngModel)]="landmark.title" class="form-control" /></td>
                <td><input [(ngModel)]="landmark.description" class="form-control" /></td>
                <td>
                  <div style="display: flex">
                    <img *ngIf="landmark.thumbnail != undefined" [src]="landmark.thumbnail" style="max-width: 64px" />
                    <input
                      *ngIf="landmark.latLng != undefined"
                      [(ngModel)]="landmark.thumbnail"
                      class="form-control"
                      placeholder="Thumbnail URL" />
                  </div>
                </td>
                <td>{{ landmark.duration | number: '1.0-0' }}</td>
                <td>
                  <button type="button" (click)="playAudio(landmark)" style="font-size: 24px">
                    <i *ngIf="audio.paused" class="fa fa-play-circle icons"></i>
                    <i *ngIf="!audio.paused" class="fa fa-stop-circle icons"></i>
                  </button>
                </td>
                <td>
                  <a
                    style="color: blue; align-self: flex-end; margin-left: 16px"
                    routerLink="/admin/editor/{{ tour.id }}/{{ landmark.id }}"
                    >Editor</a
                  >
                </td>
              </tr>
            </tbody>
          </table>
        </td>
      </tr>
    </tbody>
  </table>
</div>
<div style="text-align: center; padding: 64px" *ngIf="loading">
  <p>Loading...</p>
</div>
<div class="bottom-bar">
  <div>
    <button [disabled]="!hasChanged()" class="btn btn-primary" (click)="updateTour()">Update</button>
  </div>
</div>
