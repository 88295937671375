<div style="background: #fff9ed">
  <div style="display: flex">
    <div style="width: 100%">
      <img
        style="width: 100%; height: 100vh; object-fit: cover"
        src="../../../assets/images/alltours/unlimited_background.jpg" />
    </div>
    <div class="imgCover" style="height: 100vh">
      <div class="landingContent">
        <div class="centralMedia">
          <div class="homeBrand">
            <a class="navbar-brand" routerLink="/home">
              <div style="display: flex">
                <img id="landingIcon" src="../../assets/images/alltours/logo_white.png" />
                <span id="brandText">AllTours</span>
              </div>
            </a>
          </div>
          <div id="ctaHolder">
            <h1 i18n id="landingTitle">UNLIMITED</h1>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="container">
    <div class="row" style="place-content: center">
      <div class="col-12 col-md-6 content">
        <h5 class="header1">Get access to unlimited audio tours</h5>
        <p class="lightSubheader">Any language. Any city.</p>
        <button class="subscribeBtn" (click)="subscribe()">Subscribe for $3.33/month</button>
        <p style="color: #777; margin-top: 16px; font-weight: 200">Billed $39.99 annually.</p>
      </div>
    </div>
    <div class="row screenshotRow" style="color: #303f00; max-height: 2000px">
      <div class="col-lg-6 col-sm-12 textHolder">
        <div class="textLeft">
          <h1 class="screenshotTitle">Unlock access to our entire library of tours</h1>
          <p style="color: #0008">Never pay for an individual tour again.</p>
        </div>
      </div>
      <div class="col-lg-6 col-sm-12 screenshotHolder">
        <img
          style="height: 100%; max-width: 100%; object-fit: contain"
          src="../../../assets/images/alltours/screenshots/screenshot_3.png" />
      </div>
    </div>
    <div class="row screenshotRow" style="color: #303f00; max-height: 2000px">
      <div class="col-lg-6 col-sm-12 screenshotHolder">
        <img
          style="height: 100%; max-width: 100%; object-fit: contain"
          src="../../../assets/images/alltours/screenshots/screenshot_1.png" />
      </div>
      <div class="col-lg-6 col-sm-12 order-first order-lg-last textHolder">
        <div class="textRight">
          <h1 class="screenshotTitle">Create limitless tours in any destination</h1>
          <p style="color: #0008">In less than 1 minute.</p>
        </div>
      </div>
    </div>
  </div>
  <div>
    <div style="display: flex">
      <div style="width: 100%">
        <img
          style="width: 100%; height: 125vh; object-fit: cover"
          src="../../../assets/images/alltours/lots_of_tours.png" />
      </div>
      <div id="lotsOfTours" class="imgCover">
        <div class="container" style="font-weight: 900; text-align: center; height: 125vh; padding-bottom: 25vh">
          <div class="row" style="place-content: center; margin-top: 30vh">
            <div class="ol-12 col-md-7" style="place-content: center; text-align: center">
              <h1 class="screenshotTitle" style="color: #fff; font-weight: 900">
                A whole year of daily audio tours would cost you...
              </h1>
            </div>
          </div>
          <div class="row align-items-end" style="height: 50vh">
            <div class="col-6" style="color: #ff4545; line-height: 1.2">
              <span class="moneyTitle">$1460</span><br /><span class="moneySubTitle">WITHOUT UNLIMITED</span>
            </div>
            <div class="col-6" style="color: #00ff75; line-height: 1.2">
              <span class="moneyTitle">$40</span><br /><span class="moneySubTitle">WITH UNLIMITED</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
