import { Component, OnInit } from '@angular/core';
import firebase from 'firebase';
const db = firebase.firestore();

@Component({
  selector: 'app-admin-reviews',
  templateUrl: './admin-reviews.component.html',
  styleUrls: ['./admin-reviews.component.css'],
})
export class AdminReviewsComponent implements OnInit {
  constructor() {}

  reviews;

  ngOnInit() {
    this.getReviews();
  }

  async getReviews() {
    let snapshot = await db.collectionGroup('reviews').get();
    this.reviews = [];
    for (let querySnapshot of snapshot.docs) {
      let tourDoc = await querySnapshot.ref.parent.parent.get();
      let tour = tourDoc.data();
      let reviews = querySnapshot.data().reviews;
      for (let review of reviews) {
        review.tourTitle = tour.title;
        review.tourId = tourDoc.id;
        this.reviews.push(review);
      }
    }
    this.reviews.sort((a, b) => {
      return b.date - a.date;
    });
  }

  getDate(milliseconds) {
    return new Date(milliseconds).toLocaleString();
  }

  deleteReview(review) {
    db.collection('tours')
      .doc(review.tourId)
      .collection('reviews')
      .doc('0')
      .update({
        reviews: firebase.firestore.FieldValue.arrayRemove(review),
      })
      .then(() => {
        this.reviews = this.reviews.filter(r => r !== review);
      });
  }
}
