import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import topCitiesJson from '../../../../assets/json/top_tourist_cities.json';
import miniCitiesJson from '../../../../assets/json/mini_cities.json';
import { ActivatedRoute, Router } from '@angular/router';
import stringsJson from '../../../../assets/strings/strings.json';
import { stringFormat } from '../../../utils/utils';
import countriesNamesJson from '../../../../assets/json/countries.json';

@Component({
  selector: 'app-cities',
  templateUrl: './cities.component.html',
  styleUrls: ['./cities.component.css', '../../../app.component.css'],
})
export class CitiesComponent implements OnInit {
  cities;
  countries;
  title;
  selectedCountry;
  strings;
  language;
  countryNames;
  breadcrumbName;

  constructor(
    private router: Router,
    private titleService: Title,
    private route: ActivatedRoute
  ) {
    this.router.routeReuseStrategy.shouldReuseRoute = function () {
      return false;
    };
  }
  ngOnInit() {
    console.log("stringsJson", stringsJson)
    this.language = window.location.hostname.split('.')[0];
    if (this.language == undefined || this.language.length > 2) this.language = 'en';
    this.strings = JSON.parse(JSON.stringify(stringsJson))['default'][this.language];
    this.countryNames = JSON.parse(JSON.stringify(countriesNamesJson))

    var countryName = this.route.snapshot.paramMap.get('countryName');
    var countryCode;
    if (countryName != undefined) {
      countryName = countryName.toLowerCase();
      countryCode = Object.keys(this.countryNames).find(
        key => this.countryNames[key][this.language].toLowerCase() == countryName
      );
    }

    this.selectedCountry = countryCode != undefined ? countryCode.toLowerCase() : undefined;

    console.log('selectedCountry', this.selectedCountry, 'language', this.language, 'countryName', countryName);

    console.log('route param', countryCode);
    if (countryCode == undefined) {
      this.title = 'Top 100';
      this.breadcrumbName = this.strings.cities;
      let allCities = miniCitiesJson.cities;
      this.cities = topCitiesJson.cities.map(c => allCities.find(a => a.id == c.id)).filter(c => c != undefined);
      document.title = this.strings.alltours_audio_tours;
      this.countries = Object.values(
        allCities.reduce((r, a) => {
          r[a.countryCode] = [...(r[a.countryCode] || []), a];
          return r;
        }, {})
      );
      this.countries.sort((a, b) => b.length - a.length);
      console.log('countries', this.countries);
    } else {
      this.cities = miniCitiesJson.cities.filter(c => c.countryCode.toLowerCase() == countryCode.toLowerCase());
      let countryName = this.countryNames[this.cities[0].countryCode][this.language];
      this.breadcrumbName = countryName;
      document.title = stringFormat(this.strings.alltours_tours_in_country, countryName);
      this.title = countryName;
    }
    console.log("cities", this.cities, "language", this.language, "countryNames", this.countryNames)

    this.setSeoText();
  }

  setSeoText() {
    document.title = 'AllTours - ' + this.strings.create_tours_in_cities;
    document.head.children.namedItem('description')['content'] = this.strings.seo_description_default;
  }
}
