<div id="background1" *ngIf="posterContent != undefined">
  <div style="position: relative;">
    <div style="height: 100%; position: absolute; background: white;">
      <!-- <img style="width: 100%; height: 100%; object-fit: cover"
        src="../../../../../assets/images/alltours/screenshots/audio.jpg" /> -->
    </div>
    <div style="position: relative;">
      <img src="../../../../../assets/images/alltours/logo.png" class="businessIcon" />
      <!-- <img src="../../../../../assets/images/alltours/logo.png" class="businessIcon"
        style="position: absolute; right: 0;" /> -->
      <div style="width: 100%; display: flex;">
        <div style="width: 50%; position: relative;">
          <div style="background: #3b4d00; width: 128px; height: 50%; bottom: 0; position: absolute; right: 0;">
          </div>
          <img src="../../../../../assets/images/alltours/man_navigation.png" id="heroImage" />
        </div>
        <div style="width: 50%;">
          <div id="hookHolder1">
            <span id="cityName" *ngIf="posterContent.city != undefined">Discover {{ posterContent.city.name }}</span>
          </div>
          <div id="hookHolder2">
            <span>Get an audio tour!</span>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div id="lowerHalf">
    <div style="display: flex; padding-top: 40px;">
      <div class="contentContainer" style="width: 50%;">
        <div style="color: #daff5e;">
          <span style="font-size: 20px;">Tours from only</span><br />
          <span id="price">{{ posterContent.price }}</span>
        </div>
        <div style="margin-top: 64px;">
          <span class="infoPoint">Explore at your own pace</span><br />
          <span class="infoPoint">Enjoy our fun & engaging guides</span><br />
          <span class="infoPoint">Download tours for offline use</span>
        </div>
      </div>
      <div *ngIf="posterContent.city != undefined && posterContent.city.marketing != undefined" class="contentContainer"
        style="width: 50%; padding: 64px; padding-top: 16px;">
        <div class="scanToStartText">
          Scan to start exploring
        </div>
        <div class="qrCode">
          <img style="border-radius: 16px;" src="{{ posterContent.city.marketing.qrCode }}" />
        </div>
        <div class="urlText">
          {{ posterContent.city.marketing.link }}
        </div>
      </div>
    </div>
    <div>
      <div style="text-align: center; width: 100%; font-weight: 500; display: flex;">
        <div style="display: flex; align-items: center;" class="contentContainer">
          <i class="socialIcon fa fa-instagram"></i>
          <span>&#64;alltours.app</span>
          <div style="width: 1px; background: #fffa; height: 20px; margin-left: 12px; margin-right: 8px;"></div>
          <i class="socialIcon fa fa-envelope"></i>
          <span>kes&#64;alltours.app</span>
        </div>
        <div class="contentContainer" style="width: 100%; text-align: right;">
          <span>
            In partnership with {{ posterContent.partnerName }}
          </span>
        </div>
      </div>
    </div>
  </div>
</div>