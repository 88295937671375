import { Component, EventEmitter, OnInit, Inject, Output, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import stringsJson from '../../../../assets/strings/strings.json';
import firebase from 'firebase';
import { TinySliderInstance, tns } from 'tiny-slider';
import { fadeInUpAnimation } from 'src/app/utils/animations';

@Component({
  selector: 'app-all-tours-home',
  templateUrl: './all-tours-home.component.html',
  styleUrls: ['./all-tours-home.component.css', '../../../app.component.css'],
  animations: [fadeInUpAnimation]
})
export class AllToursHomeComponent implements OnInit {
  strings;
  language;
  screenshots;
  featuredTours = [];
  slider: TinySliderInstance;
  appStoreImage

  constructor(
    @Inject(MatDialog) public dialog: MatDialog,
    private titleService: Title,
    private route: ActivatedRoute,
    public router: Router
  ) { }

  ngOnInit() {
    this.language = window.location.hostname.split('.')[0];
    if (this.language == undefined || this.language.length > 2) this.language = 'en';
    this.strings = JSON.parse(JSON.stringify(stringsJson))['default'][this.language];
    this.setScreenshots();
    this.setSeoText();
    this.getFeaturedTours();
    this.setAppStoreImage();
  }

  setAppStoreImage() {
    let isIphone = navigator.userAgent.match(/Mac|iPhone|iPad|iPod/i)
    this.appStoreImage = isIphone ? 'appStoreButton.png' : 'googlePlayButton.png';
  }

  getFeaturedTours() {
    firebase.firestore()
      .collection('guided-tours')
      .where('language', '==', "en")
      .where('goodTour', '==', true)
      .orderBy('ratings.ratingSum', 'desc')
      .limit(3)
      .get()
      .then(querySnapshot => {
        querySnapshot.forEach(doc => {
          this.featuredTours.push(doc.data());
        });
      });
  }

  setScreenshots() {
    console.log('screenshots', this.strings.screenshot_title_0);
    this.screenshots = [
      {
        id: 0,
        title: this.strings.screenshot_title_0,
        text: this.strings.screenshot_sub_title_0,
        img: '../../../assets/images/alltours/screenshots/screenshot_0.png',
      },
      {
        id: 1,
        title: this.strings.screenshot_title_1,
        text: this.strings.screenshot_sub_title_1,
        img: '../../../assets/images/alltours/screenshots/screenshot_1.png',
      },
      {
        id: 2,
        title: this.strings.screenshot_title_2,
        text: this.strings.screenshot_sub_title_2,
        img: '../../../assets/images/alltours/screenshots/screenshot_2.png',
      },
      {
        id: 3,
        title: this.strings.screenshot_title_3,
        text: this.strings.screenshot_sub_title_3,
        img: '../../../assets/images/alltours/screenshots/screenshot_3.png',
      },
    ];
  }

  setSeoText() {
    document.title =
      this.language == 'en'
        ? 'AllTours - Audio tours in 50+ languages'
        : 'AllTours - ' + this.strings.any_city_any_language;
    document.head.children.namedItem('description')['content'] = this.strings.seo_description_default;
  }

  goToApp() {
    console.log('userAgent', navigator.userAgent);
    if (navigator.userAgent.match(/Mac|iPhone|iPad|iPod/i)) {
      this.goToAppStore()
    } else {
      this.goToPlayStore()
    }
  }

  goToAppStore() {
    firebase.analytics().logEvent('alltours_cta_click', { type: 'ios' });
    window.open('https://apps.apple.com/app/alltours-audio-tours/id6475402833?platform=iphone');
  }

  goToPlayStore() {
    firebase.analytics().logEvent('alltours_cta_click', { type: 'not-ios' });
    window.open('https://play.google.com/store/apps/details?id=app.alltours');
  }
}
