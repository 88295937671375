<div style="background: #fff">
  <div style="display: flex">
    <div style="width: 100%; height: 95vh; text-align: center">
      <iframe
        src="https://giphy.com/embed/26BRuNOYPshwxGuDm"
        width="100%"
        height="480"
        style=""
        frameBorder="0"
        class="giphy-embed"
        allowFullScreen></iframe>
    </div>
    <div class="imgCover">
      <div class="landingContent">
        <div class="centralMedia">
          <div id="ctaHolder">
            <h1 id="landingTitle">Join the iOS beta!</h1>
            <p id="landingSubtitle">
              Be one of the first to use the new AllTours app. Enjoy free, fun & engaging audio tours around the world.
            </p>
            <button type="button" (click)="goToBeta()" class="ctaBtn">Join Beta</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
