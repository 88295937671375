import { Component, OnInit } from '@angular/core';
import { PartnerService } from '../../partner-service';
import { LocalStorageService, StorageKey } from 'src/app/local-storage.service';

@Component({
  selector: 'app-partner-marketing-dashboard',
  templateUrl: './partner-marketing-dashboard.component.html',
  styleUrls: ['./partner-marketing-dashboard.component.css', '../../../../app.component.css'],
})
export class PartnerMarketingDashboardComponent implements OnInit {
  constructor(
    public partnerService: PartnerService,
    private storageService: LocalStorageService
  ) { }

  assets = [];
  marketingLink
  partner

  ngOnInit() {
    this.assets = this.posters;
    this.partnerService.partner.subscribe((partner) => {
      if (partner) {
        this.partner = partner
        this.marketingLink = partner.marketing.link
      }
    })
  }

  copyLink(link) {
    if (link != this.partner.marketing.link) {
      link += `?partner=${this.partner.id}`
    }
    navigator.clipboard.writeText(link);
  }

  hasClickedDownloadImage() {
    console.log("hasClickedDownloadImage")
    if (this.partnerService.isIndividual) {
      this.storageService.setItem(StorageKey.PARTNERS_PRINTED_ONCE, "true")
    }
  }

  posters = [
    {
      id: 1,
      title: 'Poster #1',
      image: '../../../../../assets/images/alltours/poster1.png',
    },
  ];
}
