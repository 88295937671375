<div>
  <nav style="box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2)"
    class="navbar sticky-top navbar-expand-lg navbar-light bg-light">
    <div class="container-fluid">
      <a class="navbar-brand" routerLink="">
        <div style="display: flex">
          <img id="landingIcon" src="../../assets/images/alltours/logo.png" />
          <span id="landingText">AllTours</span>
        </div>
      </a>
      <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav"
        aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>

      <div class="collapse navbar-collapse" id="navbarNav">
        <ul class="navbar-nav me-auto">
          <li class="nav-item">
            <a class="nav-link" routerLink="/partner">Dashboard</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" routerLink="/partner/marketing">Marketing</a>
          </li>
          <li class="nav-item" *ngIf="partnerService.stripeLink != undefined">
            <a class="nav-link" href="{{ partnerService.stripeLink }}">Payouts</a>
          </li>
        </ul>
        <ul class="navbar-nav ml-auto">
          <li class="nav-item" style="margin-right: 16px">
            <a class="nav-link" routerLink="/partner/contact">Contact us</a>
          </li>
          <li class="nav-item" style="margin-right: 16px">
            <a class="nav-link" (click)="logout()">Logout</a>
          </li>
        </ul>
      </div>
    </div>
  </nav>
  <div style="background: #fff; padding-bottom: 128px;">
    <div style=" color: #000; background-color: #fff; min-height: 95vh;">
      <router-outlet></router-outlet>
    </div>
  </div>
  <div id="contactFooter" style="color: #000; background-color: #fefcf4">
    <div class="container">
      <app-inner-contact-us></app-inner-contact-us>
    </div>
  </div>
  <div class="footer" style="background: #fefcf4;">
    <div class="container page-footer white font-small" style="color: grey">
      <div class="row">
        <div class="text-center py-3 col-xl-4 col-sm-6 col-sx-12" style="margin: auto; font-size: 0.75rem">
          <a routerLink="/partner/terms">Partnership Terms</a>
        </div>
      </div>
    </div>
  </div>
</div>