import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-beta',
  templateUrl: './beta.component.html',
  styleUrls: ['./beta.component.css', '../../../app.component.css'],
})
export class BetaComponent implements OnInit {
  constructor() {}

  ngOnInit() {}

  goToBeta() {
    window.open('https://testflight.apple.com/join/cGrXgV68');
  }
}
