import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import firebase from 'firebase';

declare var $: any;

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css', '../../app.component.css'],
})
export class HomeComponent implements OnInit {
  constructor(@Inject(MatDialog) public dialog: MatDialog) {}

  ngOnInit() {}

  goToApp() {
    firebase.analytics().logEvent('explorer_cta_click', { type: 'not-ios' });
    window.open('https://play.google.com/store/apps/details?id=uk.co.explorer');
  }
}
