import { Component, ElementRef, HostListener, Inject, Input, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { langs } from '../../../utils/langs';
import stringsJson from '../../../../assets/strings/strings.json';
import firebase from 'firebase';
import citiesJson from '../../../../assets/json/mini_cities.json';
import { LngLatBounds } from 'mapbox-gl';
import { setSeoText, stringFormat } from '../../../utils/utils';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import exampleTour from '../../../../assets/json/example_tour.json';
import { PurchasesService } from '../purchases.service';
import { UserService } from '../user.service';
import { slideInOutAnimation } from 'src/app/utils/animations';
import { PartnerService } from '../../partner/partner-service';
import { BreadcrumbService } from 'src/app/bread-crumb.service';

@Component({
  selector: 'app-tour',
  templateUrl: './tour.component.html',
  styleUrls: ['./tour.component.css', '../../../app.component.css'],
  animations: [slideInOutAnimation]
})
export class TourComponent implements OnInit {

  tour;
  landmarks;
  subTitle;
  description;
  playingState = NOT_PLAYING;
  audio = new Audio();
  strings;
  language;
  tourId;
  cities = citiesJson.cities;
  cityId;
  city;
  cityName;
  countryName;
  localPrice;
  loading = true;
  processingPurchase = false;
  loadingReviews = true
  reviews = [];
  checkoutError = false
  showingBottomBar = false
  tourNotFound = false
  currentUrlPlaying = undefined
  similarTours = []
  isPotentialTour = false

  tourOwnershipState = TourOwnershipState.NOT_OWNED
  TourOwnershipState = TourOwnershipState

  stops;
  line;
  bounds;

  @HostListener('window:scroll', ['$event']) onScroll(e: Event): void {
    let scrollPosition = window.scrollY || document.documentElement.scrollTop || document.body.scrollTop || 0;
    if (scrollPosition > 340 && !this.showingBottomBar) {
      this.showingBottomBar = true;
    } else if (scrollPosition < 340 && this.showingBottomBar) {
      this.showingBottomBar = false;
    }
  }

  constructor(
    @Inject(MatDialog) public dialog: MatDialog,
    private route: ActivatedRoute,
    private sanitizer: DomSanitizer,
    private router: Router,
    private purchasesService: PurchasesService,
    public userService: UserService,
    public partnerService: PartnerService,
    private breadcrumbService: BreadcrumbService
  ) { }

  ngOnInit() {
    this.language = window.location.hostname.split('.')[0];
    if (this.language == undefined || this.language.length > 2) this.language = 'en';
    this.cityName = this.route.snapshot.paramMap.get('cityName');
    this.countryName = this.route.snapshot.paramMap.get('countryName');
    this.city = this.cities.find(c => (c.names[this.language] ?? c.name).toLowerCase() == this.cityName.toLowerCase());
    this.tourId = this.route.snapshot.paramMap.get('tourId');
    console.log('cityName', this.cityName, 'countryName', this.countryName, 'tourId', this.tourId);
    this.strings = JSON.parse(JSON.stringify(stringsJson))['default'][this.language];
    this.getTour();
    this.getReviews();
  }

  getTour() {
    console.log('getTour', this.tourId);
    this.isPotentialTour = this.tourId.startsWith("p-")
    firebase.firestore().collection(this.isPotentialTour ? 'potential-tours' : 'guided-tours').doc(this.tourId).get().then(async querySnapshot => {
      console.log('querySnapshot', querySnapshot);
      if (querySnapshot.exists == false) {
        this.setTourNotFound()
      } else {
        this.tour = querySnapshot.data()
        switch (true) {
          case this.userService.hasPurchasedTour(this.tour.id):
            this.tourOwnershipState = TourOwnershipState.PURCHASED
            break
          case this.partnerService.isPartnerInCity(this.tour.city.id):
            this.tourOwnershipState = TourOwnershipState.PARTNER_IN_CITY
            break
          default:
            this.tourOwnershipState = TourOwnershipState.NOT_OWNED
        }
        console.log('tour', this.tour, "ownership", this.tourOwnershipState);
        this.landmarks = this.tour.landmarks.filter(l => l.latLng != undefined);
        let stops = this.tour.landmarks.filter(l => l.latLng != null).length;
        let languageCode = this.tour.language != undefined ? this.tour.language : this.tour.guide.language ?? "en"
        let language = langs[languageCode].nativeName;
        this.subTitle = stringFormat(this.strings.tour_sub_title, language, stops, '$2.99');
        var i = 1;
        if (this.tour.ratings != undefined && this.tour.ratings.ratingCount > 0) {
          this.tour.ratings.ratingAvg = this.tour.ratings.ratingSum / this.tour.ratings.ratingCount;
        }
        this.tour.landmarks.forEach(landmark => {
          if (landmark.latLng != undefined) {
            landmark.index = i;
            i++;
          }
        });
        this.localPrice = await this.purchasesService.getLocalPrice(this.tour);
        this.setSeoText();
        this.setRoute();
        this.getSimilarTours();
      }
    });
  }

  async getSimilarTours() {
    let cityTours = (await firebase.firestore().collection('guided-tours').where('city.id', '==', this.city.id).orderBy('ratings.ratingSum', 'desc').limit(3).get()).docs.map(doc => doc.data())
    let countryTours = (await firebase.firestore().collection('guided-tours').where('city.countryCode', '==', this.city.countryCode).orderBy('ratings.ratingSum', 'desc').limit(3).get()).docs.map(doc => doc.data())
    this.similarTours = cityTours.concat(countryTours).filter(t => t.id != this.tour.id).filter((v, i, a) => a.findIndex(t => t.id == v.id) == i).sort((a, b) => b.ratings.ratingSum - a.ratings.ratingSum).slice(0, 3)
  }

  setTourNotFound() {
    this.tourNotFound = true
  }

  getReviews() {
    firebase
      .firestore()
      .collection('guided-tours')
      .doc(this.tourId)
      .collection('reviews')
      .get()
      .then(querySnapshot => {
        this.reviews = []
        querySnapshot.docs.forEach(doc => {
          doc.data().reviews.forEach(review => {
            this.reviews.push(review);
          });
        });
        this.loadingReviews = false;
      });
  }

  getSafeHTML(value: {}) {
    const json = JSON.stringify(value, null, 2);
    const html = `<script type="application/ld+json">${json}</script>`;
    // Inject to inner html without Angular stripping out content
    return this.sanitizer.bypassSecurityTrustHtml(html);
  }

  getLanguage(lang) {
    langs[lang].nativeName;
  }

  async playAudio(audioUrl = undefined) {
    let currentUrlPlaying = audioUrl == undefined ? this.tour.landmarks[0].audioUrl : audioUrl;
    if (this.playingState == PLAYING && this.currentUrlPlaying == currentUrlPlaying) {
      this.audio.pause();
      this.playingState = NOT_PLAYING;
    } else {
      this.audio.pause();
      this.currentUrlPlaying = currentUrlPlaying
      this.playingState = LOADING;
      this.audio.src = currentUrlPlaying;
      this.audio.load();
      await this.audio.play();
      this.playingState = PLAYING;
      this.audio.addEventListener('ended', () => {
        this.playingState = NOT_PLAYING;
      });
    }
  }

  setRoute() {
    this.stops = {
      type: 'FeatureCollection',
      features: this.tour.landmarks
        .filter(l => l.latLng != undefined)
        .map(stop => ({
          type: 'Feature',
          properties: {
            stop: stop,
          },
          geometry: {
            type: 'Point',
            coordinates: [stop.latLng[1], stop.latLng[0]],
          },
        })),
    };
    if (this.tour.routeInstructions != undefined) {
      this.line = this.tour.routeInstructions.geometry.map(x => [x.lng, x.lat]);
    } else {
      this.line = this.stops.features.map(x => x.geometry.coordinates);
    }
    if (this.line.length > 0) {
      this.bounds = this.line.reduce(
        (bounds, coord) => {
          return bounds.extend(<any>coord);
        },
        new LngLatBounds(this.line[0], this.line[0])
      );
    }
    this.loading = false;
  }

  @ViewChild('loginModal', { static: true }) loginModalRef!: ElementRef;

  async buyTour() {
    firebase.analytics().logEvent('buy_tour_click');

    this.showCheckingOutModal();
    try {
      let url = await this.purchasesService.getCheckoutUrl(
        this.tourId,
        this.localPrice.id,
        window.location.href,
        `${window.location.origin}/tour-purchased/${this.tourId}`,
        this.userService.getUserId(),
        this.userService.getUserEmail()
      );
      window.location.href = url;
    } catch (e) {
      console.error(e);
      this.checkoutError = true;
    }
  }

  onSuccessfulLogin(event) {
    console.log('onSuccessfulLogin event', event);
    this.closeLoginModal();
    this.buyTour();
  }

  closeLoginModal() {
    document.getElementById('closeLoginBtn').click();
  }

  showCheckingOutModal() {
    this.checkoutError = false
    document.getElementById('openCheckingOutModalBtn').click();
  }

  goToApp() {
    if (navigator.userAgent.match(/Mac|iPhone|iPad|iPod/i)) {
      firebase.analytics().logEvent('alltours_cta_click', { type: 'ios' });
      window.open('https://apps.apple.com/app/alltours-audio-tours/id6475402833?platform=iphone');
    } else {
      firebase.analytics().logEvent('alltours_cta_click', { type: 'not-ios' });
      window.open('https://play.google.com/store/apps/details?id=app.alltours');
    }
  }

  setSeoText() {
    setSeoText(`AllTours: ${this.tour.title}`, this.tour.description)

    let product = {
      '@context': 'https://schema.org/',
      '@type': 'Product',
      name: this.tour.title,
      description: this.description,
      image: [this.tour.image ? this.tour.image.medium : undefined],
      "offers": {
        "@type": "Offer",
        "price": this.localPrice.price,
        "priceCurrency": "USD",
        "availability": "https://schema.org/InStock"
      },
      brand: {
        '@type': 'Brand',
        name: 'AllTours',
        logo: 'https://alltours.app/assets/images/alltours/logo_app.png',
      },
    };
    const ratings = this.tour.ratings;
    if (ratings != undefined && ratings.ratingCount > 0) {
      product['aggregateRating'] = {
        '@type': 'AggregateRating',
        ratingValue: ratings.ratingSum / ratings.ratingCount,
        reviewCount: ratings.ratingCount,
      };
    }
    if (ratings != undefined && ratings.featuredReview != undefined) {
      product['review'] = {
        '@type': 'Review',
        reviewRating: {
          '@type': 'Rating',
          ratingValue: this.tour.ratings.featuredReview.rating,
          bestRating: 5,
        },
        author: {
          '@type': 'Person',
          name: 'Anonymous',
        },
      };
    }

    this.breadcrumbService.addProductDetails(product)
  }

  howItWorks = [
    {
      title: "Buy the tour",
      description: "You'll be taken to a secure checkout page.",
      icon: "shopping_cart"
    },
    {
      title: 'Download the AllTours app',
      description: "It shouldn't take long!",
      icon: "download"
    },
    {
      title: 'Login to the app',
      description: "You'll see the tour you just bought.",
      icon: "passkey"
    },
    {
      title: 'Start the tour!',
      description: "You'll be guided through the city with audio and a map.",
      icon: "play_arrow"
    },
  ];

  allToursBenefits = [
    {
      title: "Explore at Your Own Pace",
      description: "Take breaks, linger at landmarks, or move quickly—it's your tour, on your time.",
      icon: "self_improvement"
    },
    {
      title: "Ask Questions Anytime",
      description: "Got a question? Ask the in-app guide at any point during the tour for more information about what you’re seeing.",
      icon: "person_raised_hand"
    },
    {
      title: "GPS-Triggered Audio",
      description: "As you approach each landmark, the audio guide plays automatically, making your tour seamless.",
      icon: "share_location"
    },
    {
      title: "Follow a Guided Route",
      description: "Navigate the city effortlessly with AllTours' built-in map, guiding you from one landmark to the next.",
      icon: "route"
    },
    {
      title: "Customize Your Tour",
      description: "Add extra audio tracks to dive deeper into the city’s history, politics, culture, and more, tailoring your experience.",
      icon: "instant_mix"
    },
    {
      title: "No Internet Needed",
      description: "Download the tour in advance and enjoy it offline—no Wi-Fi or data required.",
      icon: "wifi_off"
    },
    {
      title: "Tour Anytime, Anywhere",
      description: "Unlike scheduled group tours, you can start your audio tour whenever you like—day or night.",
      icon: "partly_cloudy_day"
    }
  ];

  faqs = [
    {
      title: 'What is an audio tour?',
      description:
        'An audio tour is like having a tour guide on your phone. You are shown a map & a route so you know how to get to each landmark. The audio then begins playing when you reach each landmark.',
    },
    {
      title: 'What do I do if the app is not working properly?',
      description: "If you're experiencing issues, try restarting the app. If problems persist, ensure your app is up-to-date or contact <a href='mailto:kes@alltours.app'>support</a> for assistance.",
    },
    {
      title: 'Do I need an account to use AllTours?',
      description: "To purchase a tour on our website you'll first need to create an account so that we can sync your purchases with the app. You'll need to login to the app with the same account to access your tours."
    },
    {
      title: 'Can I pause or resume a tour?',
      description: 'Yes, you can pause and resume any tour at your convenience. The app will remember where you left off so you can continue later.',
    },
    {
      title: 'What data does AllTours collect?',
      description: 'AllTours collects minimal data necessary to provide location-based services. For more information, you can view our <a href="https://alltours.app/policy" target="_blank">Privacy Policy</a>.',
    },
    {
      title: 'Do I need internet access to use AllTours?',
      description: 'All tours can be downloaded for offline use, but for using the Chat feature, an internet connection is required.',
    },
    {
      title: 'Is AllTours free to use?',
      description: 'AllTours is free to download but the audio tours are paid. You can purchase tours on our website or in the app.',
    },
  ];
}

const NOT_PLAYING = 0;
const LOADING = 1;
const PLAYING = 2;

enum TourOwnershipState {
  NOT_OWNED,
  PURCHASED,
  PARTNER_IN_CITY
}