<div>
  <div [ngStyle]="{'display': loggingInState == LoggingInState.NOT_LOGGING_IN ? 'block' : 'none' }">
    <div style="width: 100%">
      <p *ngIf="emailLoginState == EmailLoginState.NOT_SELECTED">{{ loginMessage }}</p>
      <p *ngIf="emailLoginState == EmailLoginState.INVALID_EMAIL">Enter your email...</p>
      <p *ngIf="emailLoginState == EmailLoginState.CHECKING_EMAIL">Checking email...</p>
      <p *ngIf="emailLoginState == EmailLoginState.UNKNOWN_EMAIL">Welcome! Please create your account...</p>
      <p *ngIf="emailLoginState == EmailLoginState.KNOWN_EMAIL">Welcome back! Please enter your password...</p>
    </div>
    <div *ngIf="emailLoginState == EmailLoginState.NOT_SELECTED">
      <button (click)="googleLogin()" class="btn authBtn"><i class="fa fa-google authIcon"></i> Google</button>
      <button (click)="appleLogin()" class="btn authBtn"><i class="fa fa-apple authIcon"></i> Apple</button>
      <button (click)="emailLogin()" class="btn authBtn"><i class="fa fa-envelope authIcon"></i> Email</button>
    </div>
    <div *ngIf="emailLoginState != EmailLoginState.NOT_SELECTED">
      <form id="emailForm">
        <div class="mb-3 input-group flex-nowrap">
          <input name="emailAddress" id="emailAddress" type="email" class="form-control" maxlength="200" required
            (input)="onEmailChange($event)" />
          <div class="input-group-text" *ngIf="emailLoginState == EmailLoginState.CHECKING_EMAIL">
            <div class="loader"></div>
          </div>
        </div>
        <div class="mb-3" *ngIf="emailLoginState >= EmailLoginState.KNOWN_EMAIL">
          <label for="password" class="form-label">Password</label>
          <input name="password" id="password" type="password" class="form-control" maxlength="200" required />
        </div>
        <div class="mb-3" *ngIf="emailLoginState == EmailLoginState.UNKNOWN_EMAIL">
          <label for="confirmPassword" class="form-label">Confirm password</label>
          <input name="confirmPassword" id="confirmPassword" type="password" class="form-control" maxlength="200"
            required />
        </div>
        <div class="col-12" style="text-align: center">
          <button *ngIf="emailLoginState >= EmailLoginState.KNOWN_EMAIL" class="ctaBtnMini" type="submit"
            style="width: 100%; margin-top: 16px">
            <span *ngIf="emailLoginState == EmailLoginState.KNOWN_EMAIL">Login</span>
            <span *ngIf="emailLoginState == EmailLoginState.UNKNOWN_EMAIL">Create Account</span>
          </button>
        </div>
      </form>
      <div
        *ngIf="emailLoginState == EmailLoginState.INVALID_EMAIL || emailLoginState == EmailLoginState.UNKNOWN_EMAIL || emailLoginState == EmailLoginState.KNOWN_EMAIL"
        style="width: 100%; text-align: center; margin-top: 16px">
        <a style="color: #990" (click)="returnToLoginOptions()">Back to login options</a>
      </div>
    </div>
  </div>
  <div *ngIf="loggingInState != LoggingInState.NOT_LOGGING_IN" style="text-align: center; padding: 48px;">
    <div *ngIf="loggingInState == LoggingInState.LOGGING_IN">
      <div class="loader" style="margin: auto; padding: 8px;"></div>
      Logging in...
    </div>
    <div *ngIf="loggingInState == LoggingInState.SETTING_UP">
      <div class="loader" style="margin: auto; padding: 8px;"></div>
      Setting up account...
    </div>
    <div *ngIf="loggingInState == LoggingInState.LOGGED_IN">
      <i class="fa fa-check" style="color: #090"></i><br />Logged in!
    </div>
  </div>
</div>