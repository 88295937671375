<h2 style="padding: 1vh; text-align: center">Reviews</h2>
<div class="table-responsive">
  <table class="table table-bordered table-striped">
    <thead class="thead-dark">
      <tr>
        <th scope="col">Rating</th>
        <th scope="col" style="min-width: 200px">Review</th>
        <th scope="col">Tour</th>
        <th scope="col">Date</th>
        <th scope="col">Actions</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let review of reviews">
        <td>
          <div class="star-rating">
            <span *ngFor="let star of [1, 2, 3, 4, 5]" [ngClass]="{ starRated: star <= review.rating }">&#9733;</span>
          </div>
        </td>
        <td>{{ review.review }}</td>
        <td>
          {{ review.tourTitle }}
          <br />
          <br />
          <a
            style="color: orange; align-self: flex-end"
            target="_blank"
            href="https://console.firebase.google.com/u/0/project/explorer-f45e6/firestore/databases/-default-/data/~2Fguided-tours/{{
              review.tourId
            }}"
            >Firestore</a
          >
          <a style="color: blue; align-self: flex-end; margin-left: 16px" routerLink="/admin/editor/{{ review.tourId }}"
            >Editor</a
          >
        </td>
        <td scope="row">{{ review.formattedDate }}</td>
        <td style="color: red" (click)="deleteReview(review)">Delete</td>
      </tr>
    </tbody>
  </table>
</div>
<!-- <div style="text-align: center;" *ngIf="!loading">
    <p *ngIf="endOfData" style="text-align: center;">End of data</p>
    <button *ngIf="!endOfData" class="btn btn-primary" (click)="getMoreData(5)">Load more</button>
</div>
<div style="text-align: center; padding: 64px;" *ngIf="loading">
    <p>Loading...</p>
</div> -->
