<div class="container">
  <div class="row" style="padding-top: 96px">
    <div class="col-12 col-md-6" style="padding: 3%">
      <h2>Tour codes</h2>
      <div class="form-group">
        <label for="code1">Code:</label>
        <input [(ngModel)]="tourCode" type="text" class="form-control" placeholder="Enter code (optional)" />
      </div>
      <div class="form-group">
        <label for="code1">Tour ID:</label>
        <input [(ngModel)]="tourId" type="text" class="form-control" placeholder="Enter tour ID" required />
      </div>
      <button
        class="btn btn-primary"
        (click)="createTourCode()"
        [disabled]="tourId == undefined || tourId.length < 10 || creatingTourCode">
        Create Code
      </button>
      <table class="table table-bordered" style="margin-top: 96px">
        <thead class="thead-dark">
          <tr>
            <th scope="col">Code</th>
            <th scope="col">Tour ID</th>
            <th scope="col">Action</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let code of tourCodes; let i = index">
            <td>{{ code.code }}</td>
            <td>{{ code.tourId }}</td>
            <td style="color: red" (click)="deleteCode(code.code, false)">Delete</td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="col-12 col-md-6" style="padding: 3%">
      <h2>Unlimited codes</h2>
      <div class="form-group">
        <label for="code2">Code:</label>
        <input [(ngModel)]="unlimitedCode" type="text" class="form-control" placeholder="Enter code (optional)" />
      </div>
      <div class="form-group">
        <label for="code1">Days:</label>
        <input [(ngModel)]="unlimitedDays" type="number" class="form-control" placeholder="Enter days (optional)" />
      </div>
      <button class="btn btn-primary" (click)="createUnlimitedCode()" [disabled]="creatingUnlimitedCode">
        Create Code
      </button>
      <table class="table table-bordered" style="margin-top: 96px">
        <thead class="thead-dark">
          <tr>
            <th scope="col">Code</th>
            <th scope="col">Days</th>
            <th scope="col">Action</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let code of unlimitedCodes; let i = index">
            <td>{{ code.code }}</td>
            <td>{{ code.days }}</td>
            <td style="color: red" (click)="deleteCode(code.code, true)">Delete</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
