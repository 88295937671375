import { Component, OnInit } from '@angular/core';
import firebase from 'firebase';
import { PartnerService } from '../../partner-service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-partner-login',
  templateUrl: './partner-login.component.html',
  styleUrls: ['./partner-login.component.css', '../../../../app.component.css'],
})
export class PartnerLoginComponent implements OnInit {

  loading

  constructor(private partnerService: PartnerService, private router: Router) { }

  ngOnInit() {
    this.setupForm()
  }

  setupForm() {
    var form = document.getElementById('loginForm')
    let thisRef = this
    form.addEventListener('submit', function (event) {
      event.preventDefault()
      thisRef.loading = true
      const formProps = Object.fromEntries(new FormData(event.target as any) as any);
      console.log("formProps", formProps)
      let email = formProps.emailAddress
      let password = formProps.password

      thisRef.partnerService.login(email, password, (success, error) => {
        if (success) {
          console.log("success")
          thisRef.router.navigate(['/partner'])
        } else {
          alert(error)
          console.log("error", error)
          thisRef.loading = false
        }
      })
    })
  }
}
