import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-partner-statistics',
  templateUrl: './partner-statistics.component.html',
  styleUrls: ['./partner-statistics.component.css'],
})
export class PartnerStatisticsComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
