import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-screenshots',
  templateUrl: './screenshots.component.html',
  styleUrls: ['./screenshots.component.css'],
})
export class ScreenshotsComponent implements OnInit {
  constructor() {}

  screenshots;

  ngOnInit() {
    this.screenshots = [0, 5, 1, 2, 3, 4, 6, 7];
  }
}
