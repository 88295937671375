import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { PosterContent } from '../PosterContent';

@Component({
  selector: 'app-partner-poster-1',
  templateUrl: './partner-poster.component.html',
  styleUrls: ['./partner-poster.component.css', '../../../../../app.component.css'],
})
export class PartnerPosterComponent1 implements OnInit {
  constructor() { }

  @Input() posterContent: PosterContent;

  ngOnInit() {
    console.log("posterContent", this.posterContent)
  }

  ngAfterViewInit() {
    this.resizeTextToFit();
  }

  resizeTextToFit() {
    const container = document.getElementById('hookHolder1');
    const lineHeight = parseFloat(window.getComputedStyle(container).lineHeight); // Get the line height
    const maxLines = 2; // We want to fit text into two lines
    let fontSize = parseFloat(window.getComputedStyle(container).fontSize); // Start with the initial font size

    // Calculate the maximum height allowed for two lines of text
    const maxHeight = lineHeight * maxLines * 2

    // Reduce the font size until the text fits within two lines
    while (container.scrollHeight > maxHeight && fontSize > 1) {
      fontSize -= 1;
      container.style.fontSize = `${fontSize}px`;
    }
  }

}
