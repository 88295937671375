import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import firebase from 'firebase';

@Component({
  selector: 'app-product',
  templateUrl: './product.component.html',
  styleUrls: ['./product.component.css'],
})
export class ProductComponent implements OnInit {
  tours = [];
  loading = false;
  product = {};

  constructor() {}

  ngOnInit() {}

  search(searchInput: string) {
    this.loading = true;

    const regex = /^[a-fA-F0-9]{8}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{12}$/;
    if (regex.test(searchInput)) {
      firebase
        .firestore()
        .collection('guided-tours')
        .doc(searchInput)
        .get()
        .then(doc => {
          if (doc.exists) {
            this.tours.push(doc.data());
            console.log('Document data:', doc.data());
          } else {
            alert('No such document!');
          }
          this.loading = false;
        })
        .catch(error => {
          console.log('Error getting document:', error);
          this.loading = false;
          alert('Error getting document:');
        });
    } else {
      firebase
        .functions()
        .httpsCallable('searchToursCall')({ query: searchInput })
        .then(result => {
          console.log('result', result);
          this.tours = result.data.hits;
          this.loading = false;
        });
    }
  }

  selectTour(tour) {
    console.log('tour', tour);
    this.tours = [];
    this.loading = true;
    console.log('creating product for id:' + tour.id);

    firebase
      .functions()
      .httpsCallable('createSupplierTourProductCall')({ tourId: tour.id })
      .then(result => {
        console.log('result', result);
        this.product = result.data;
        console.log('product created', this.product);
        this.loading = false;
      })
      .catch(error => {
        console.log('error', error);
        this.loading = false;
        alert('error creating product');
      });
  }

  copyToClipboard(target) {
    console.log('copying to clipboard', target);
    navigator.clipboard.writeText(target.value);
  }

  downloadImages() {
    var i = 0;
    for (let image of this.product['images']) {
      i++;
      this.download(image, 'image' + i + '.jpg');
    }
  }

  download(url, filename) {
    console.log('downloading', url, filename);
    fetch(url)
      .then(response => response.blob())
      .then(blob => {
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = filename;
        link.click();
      })
      .catch(console.error);
  }
}
