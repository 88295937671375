<div style="background: #fff; color: #000000aa">
  <div class="container">
    <div class="row" style="height: 92vh">
      <div class="col-12" style="align-self: center">
        <h1>Delete your account</h1>
        <p>
          Please send us an email requesting the deletion of your account using the email address associated with your
          account.
        </p>
        <p>
          We will delete your account (including your email, username & all other data) from our database within 48
          hours of receiving your email.
        </p>
        <a href="mailto:kes@alltours.app"><button type="button" class="ctaBtnMini">Contact us</button></a>
      </div>
    </div>
  </div>
</div>
