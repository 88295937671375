<div style="display: flex">
  <div *ngIf="error" style="text-align: center">
    <h5 style="position: absolute; top: 50%">Sorry, couldn't find the trip you were looking for.</h5>
  </div>
  <mgl-map
    [style]="'mapbox://styles/kes-at-explorer/cldkmxdfq007m01lfqiqbiskp'"
    [zoom]="[1]"
    [fitBounds]="bounds"
    [fitBoundsOptions]="{ padding: 20 }">
    <mgl-layer
      id="routeOutline"
      type="line"
      [source]="{
        type: 'geojson',
        data: {
          type: 'Feature',
          properties: {},
          geometry: {
            type: 'LineString',
            coordinates: line,
          },
        },
      }"
      [layout]="{
        'line-join': 'round',
        'line-cap': 'round',
      }"
      [paint]="{
        'line-color': '#000',
        'line-width': 8,
      }">
    </mgl-layer>
    <mgl-layer
      id="route"
      type="line"
      [source]="{
        type: 'geojson',
        data: {
          type: 'Feature',
          properties: {},
          geometry: {
            type: 'LineString',
            coordinates: line,
          },
        },
      }"
      [layout]="{
        'line-join': 'round',
        'line-cap': 'round',
      }"
      [paint]="{
        'line-color': '#F00',
        'line-width': 4,
      }">
    </mgl-layer>
    <mgl-layer
      id="completedRoute"
      type="line"
      [source]="{
        type: 'geojson',
        data: {
          type: 'Feature',
          properties: {},
          geometry: {
            type: 'LineString',
            coordinates: completedLine,
          },
        },
      }"
      [layout]="{
        'line-join': 'round',
        'line-cap': 'round',
      }"
      [paint]="{
        'line-color': '#B7D167',
        'line-width': 4,
      }">
    </mgl-layer>
    <mgl-marker *ngFor="let feature of stops.features; let i = index" [feature]="feature">
      <div
        (click)="selectedStop = feature.properties.stop"
        class="marker"
        [ngStyle]="{
          'background-color': feature.properties.inProgress
            ? '#FFC107'
            : feature.properties.complete
              ? '#B7D167'
              : '#f00',
        }">
        <span>{{ i + 1 }}</span>
      </div>
    </mgl-marker>
  </mgl-map>
  <div class="bottomsheet">
    <div class="container">
      <div class="col-12">
        <div class="row">
          <div *ngIf="selectedStop === undefined">
            <h5>{{ trip.title }}</h5>
            <p *ngIf="trip.shareDates && trip.startDate !== null">
              {{ trip.startDate.toDate() | date: 'dd MMM yyyy' }} - {{ trip.endDate.toDate() | date: 'dd MMM yyyy' }}
            </p>
          </div>
          <div *ngIf="selectedStop !== undefined">
            <h5>{{ selectedStop.title }}</h5>
            <p *ngIf="trip.shareDates">
              {{ selectedStop.startDate.toDate() | date: 'dd MMM yyyy' }} -
              {{ selectedStop.endDate.toDate() | date: 'dd MMM yyyy' }}
            </p>
          </div>
          <div
            *ngIf="trip.shareProgress"
            style="display: flex; font-size: 12px; text-align: center; display: flex; place-items: center">
            <div class="box" style="background-color: #b7d167; margin-left: 0px"></div>
            <span>Complete</span>
            <div class="box" style="background-color: #ffc107"></div>
            <span>Current Location</span>
            <div class="box" style="background-color: #f00"></div>
            <span>Undiscovered</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
