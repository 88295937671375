<div class="container" *ngIf="userService.userData != undefined">
    <div class="row">
        <div class="col-12">
            <div style="margin-top: 64px; margin-bottom: 64px; display: flex;">
                <div style="width: 100%; display: flex;">
                    <img src="{{ userService.userData.photo }}"
                        onerror="this.onerror=null; this.src='../../../../../../../../assets/images/alltours/profile_img.jpg'"
                        class="card-img-top" alt="User Image" style="width: 128px; height: 128px;">
                    <div style="padding-left: 16px;">
                        <h5>{{userService.userData.name ? userService.userData.name : "Explorer"}}</h5>
                        <p>{{userService.userData.email }}</p>
                    </div>
                </div>
                <div>
                    <button class="btn" (click)="logout()">Logout</button>
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="userService.tours$ | async as tours" class="row" style="place-content: center">
        <hr />
        <h5>Your Tours</h5>
        <div class="col-12 col-md-6 col-xl-4 tourPreviewOuterContainer" *ngFor="let tour of tours"
            style="padding: 0; text-align: -webkit-center">
            <app-tour-preview [tour]="tour"></app-tour-preview>
        </div>
        <div *ngIf="tours == undefined || tours.length == 0">
            <p>No tours found</p>
        </div>
    </div>
</div>

<div style="padding: 20%; text-align: center;" *ngIf="userService.userData == undefined">
    <div class="loader" style="margin: auto;"></div>
    <p>Loading your profile...</p>
</div>