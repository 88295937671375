import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-partner-marketing',
  templateUrl: './partner-marketing.component.html',
  styleUrls: ['./partner-marketing.component.css', '../../../app.component.css'],
})
export class PartnerMarketingComponent implements OnInit {
  constructor() { }

  ngOnInit() { }
}
