<h2 style="padding: 1vh; text-align: center">Tours</h2>
<div class="table-responsive">
  <table class="table table-bordered table-striped">
    <thead class="thead-dark">
      <tr>
        <th scope="col">Created</th>
        <th scope="col" style="min-width: 200px">Title</th>
        <th scope="col">City</th>
        <th scope="col">Country</th>
        <th scope="col">Version</th>
        <th scope="col">Language</th>
        <th scope="col">Landmarks</th>
        <th scope="col">Rating</th>
        <th scope="col">Good Tour</th>
        <th scope="col">Delete</th>
        <th scope="col">Reviewed</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let tour of tours" [ngClass]="{ 'table-warning': tour.qualityReviewed == false }">
        <th scope="row">{{ getDate(tour.created) }}</th>
        <td>
          {{ tour.title }}
          <br />
          <br />
          <a style="color: orange; align-self: flex-end" target="_blank" href="https://console.firebase.google.com/u/0/project/explorer-f45e6/firestore/databases/-default-/data/~2Fguided-tours/{{
              tour.id
            }}">Firestore</a>
          <a style="color: green; align-self: flex-end; margin-left: 16px" target="_blank"
            href="https://alltours.app/tour/{{ tour.city.countryName }}/{{ tour.city.name }}/{{ tour.id }}">AllTours</a>
          <a style="color: blue; align-self: flex-end; margin-left: 16px"
            routerLink="/admin/editor/{{ tour.id }}">Editor</a>
        </td>
        <td>
          <a href="https://alltours.app/tour/{{ tour.city.countryName }}/{{ tour.city.name }}" target="_blank">
            {{ tour.city.name }}
          </a>
        </td>
        <td>
          <a href="https://alltours.app/tour/{{ tour.city.countryName }}" target="_blank">
            {{ tour.city.countryName }}
          </a>
        </td>
        <td>{{ tour.version }}</td>
        <td>
          <p>{{ tour.language }}</p>
          <div *ngIf="tour.translations != undefined">
            Translations:
            <ul>
              <li *ngFor="let lang of tour.translations">
                <a style="color: blue; align-self: flex-end; margin-left: 16px"
                  routerLink="/admin/editor/translation/{{ lang }}/{{ tour.id }}">{{ lang }}</a>
              </li>
            </ul>
          </div>
        </td>
        <td>{{ tour.landmarkCount }}</td>
        <td>
          <span *ngIf="tour.ratings.ratingCount != 0">{{ tour.ratings.ratingSum / tour.ratings.ratingCount }} / 5.0
          </span>({{ tour.ratings.ratingCount }})
        </td>
        <td>{{ tour.goodTour }}</td>
        <td style="color: red" (click)="deleteTour(tour)">Delete</td>
        <td (click)="toggleReviewed(tour)">
          <i *ngIf="tour.qualityReviewed" class="fa fa-check-square"></i>
          <i *ngIf="!tour.qualityReviewed" class="fa fa-square-o"></i>
        </td>
      </tr>
    </tbody>
  </table>
</div>
<div style="text-align: center" *ngIf="!loading">
  <p *ngIf="endOfData" style="text-align: center">End of data</p>
  <button *ngIf="!endOfData" class="btn btn-primary" (click)="getMoreData(5)">Load more</button>
</div>
<div style="text-align: center; padding: 64px" *ngIf="loading">
  <p>Loading...</p>
</div>