<div class="container" style="padding-bottom: 256px">
  <div class="row">
    <div class="col-md-6">
      <div class="form-group"></div>
      <label for="autocomplete">Search</label>
      <div style="display: flex">
        <input
          #searchInput
          type="text"
          class="form-control"
          id="autocomplete"
          placeholder="Search"
          autocomplete="off" />
        <button *ngIf="!loading" (click)="search(searchInput.value)" type="button" class="btn btn-primary" id="search">
          <i class="fas fa-search"></i>
        </button>
      </div>
      <div *ngIf="loading" class="spinner-border" role="status">
        <span class="sr-only">Loading...</span>
      </div>
      <div id="autocomplete-results" *ngFor="let tour of tours">
        <div class="searchResult" style="display: flex">
          <div>
            <img
              *ngIf="tour.landmarks != undefined"
              class="searchImg"
              src="{{ tour.landmarks[1].imageUrl }}"
              alt="tour image" />
            <img *ngIf="tour.imageUrl != undefined" class="searchImg" src="{{ tour.imageUrl }}" alt="tour image" />
          </div>
          <div>
            <h6>{{ tour.title }}</h6>
            <span *ngIf="tour.landmarks != undefined">{{ tour.landmarks.length }} Landmarks</span><br />
            <span *ngIf="tour.totalAudioDuration != undefined">{{ tour.totalAudioDuration / 60 }} mins of audio</span
            ><br />
            <span *ngIf="tour.stops != undefined">{{ tour.stops }}</span
            ><br />
            <span>{{ tour.id }}</span>
          </div>

          <button (click)="selectTour(tour)" type="button" class="btn btn-success" id="generate">
            <i class="fas fa-cogs"></i>
          </button>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-md-6">
      <form>
        <div class="form-group">
          <label for="title">Title</label>
          <input
            [(ngModel)]="product['title']"
            name="title"
            type="text"
            class="form-control"
            id="title"
            readonly
            (click)="copyToClipboard($event.target)" />
        </div>
        <div class="form-group">
          <label for="shortId">Short ID</label>
          <input
            [(ngModel)]="product['shortId']"
            name="shortId"
            type="text"
            class="form-control"
            id="shortId"
            readonly
            (click)="copyToClipboard($event.target)" />
        </div>
        <div class="form-group">
          <label for="introduction">Introduction</label>
          <input
            [(ngModel)]="product['introduction']"
            name="introduction"
            type="text"
            class="form-control"
            id="introduction"
            readonly
            (click)="copyToClipboard($event.target)" />
        </div>
        <div class="form-group">
          <label for="longDescription">Long Description</label>
          <textarea
            [(ngModel)]="product['description']"
            name="description"
            class="form-control"
            id="longDescription"
            rows="4"
            readonly
            (click)="copyToClipboard($event.target)"></textarea>
        </div>
        <div class="form-group">
          <label for="highlights">Highlights</label>
          <div>
            <div *ngFor="let highlight of product['highlights']">
              <input
                value="{{ highlight }}"
                name="highlights"
                type="text"
                class="form-control"
                id="highlights"
                readonly
                (click)="copyToClipboard($event.target)" />
            </div>
          </div>
        </div>
        <div class="form-group">
          <label for="locations">Locations</label>
          <div>
            <div *ngFor="let location of product['locations']">
              <input
                value="{{ location }}"
                name="locations"
                type="text"
                class="form-control"
                id="locations"
                readonly
                (click)="copyToClipboard($event.target)" />
            </div>
          </div>
        </div>
        <div class="form-group">
          <label for="keywords">Keywords</label>
          <input
            [(ngModel)]="product['keywords']"
            name="keywords"
            type="text"
            class="form-control"
            id="keywords"
            readonly
            (click)="copyToClipboard($event.target)" />
        </div>
        <div class="form-group">
          <label for="inclusions">Inclusions</label>
          <input
            [(ngModel)]="product['included']"
            name="inclusions"
            type="text"
            class="form-control"
            id="inclusions"
            readonly
            (click)="copyToClipboard($event.target)" />
        </div>
        <div class="form-group">
          <label for="uninclusions">Uninclusions</label>
          <input
            [(ngModel)]="product['notIncluded']"
            name="uninclusions"
            type="text"
            class="form-control"
            id="uninclusions"
            readonly
            (click)="copyToClipboard($event.target)" />
        </div>
        <div class="form-group">
          <label for="customInfo">Custom Info</label>
          <input
            [(ngModel)]="product['customInfo']"
            name="customInfo"
            type="text"
            class="form-control"
            id="customInfo"
            readonly
            (click)="copyToClipboard($event.target)" />
        </div>
        <div class="form-group">
          <label for="totalDuration">Total Duration</label>
          <input
            [(ngModel)]="product['totalDuration']"
            name="totalDuration"
            type="text"
            class="form-control"
            id="totalDuration"
            readonly
            (click)="copyToClipboard($event.target)" />
        </div>
        <div class="form-group">
          <label for="meetingPoint">Meeting Point</label>
          <input
            [(ngModel)]="product['meetingPoint']"
            name="meetingPoint"
            type="text"
            class="form-control"
            id="meetingPoint"
            readonly
            (click)="copyToClipboard($event.target)" />
        </div>
        <div *ngIf="product['images'] != undefined">
          <label for="images">Images</label>
          <div style="display: flex">
            <input
              value="{{ product['images'].length }} Images"
              name="images"
              type="text"
              class="form-control"
              id="meetingPoint"
              readonly />
            <button (click)="downloadImages()" type="button" class="btn btn-success" id="download">
              <i class="fas fa-download"></i>
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
