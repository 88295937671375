import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-partner-terms',
  templateUrl: './partner-terms.component.html',
  styleUrls: ['./partner-terms.component.css'],
})
export class PartnerTermsComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
