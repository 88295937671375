<div>
  <div style="display: flex">
    <div style="width: 100%">
      <img style="width: 100%; height: 95vh; object-fit: cover" src="../../../assets/images/explorer/explorer.jpg" />
    </div>
    <div class="imgCover">
      <div class="landingContent">
        <div class="centralMedia">
          <div id="ctaHolder">
            <h1 id="landingTitle">Discover the world</h1>
            <p style="font-size: 20px">Plan, explore & discover with the all-in-one Explorer App</p>
            <button type="button" (click)="goToApp()" class="ctaBtn">Start Exploring</button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <app-reviews></app-reviews>

  <app-screenshots></app-screenshots>
</div>
