import { Component, OnInit, Inject } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { MatDialog } from '@angular/material/dialog';
import firebase from 'firebase';

@Component({
  selector: 'app-explorer-app',
  templateUrl: './explorer-app.component.html',
  styleUrls: ['../app.component.css'],
})
export class ExplorerAppComponent implements OnInit {
  todaysDate = Date.now();

  constructor(@Inject(MatDialog) public dialog: MatDialog) {
    this.setSeoText();
  }

  ngOnInit() {
    this.setSeoText();
  }

  setSeoText() {
    const linkElement = document.querySelector('link[rel=icon]');
    linkElement['href'] = '../../assets/images/explorer/favicon.ico';
    document.title = 'Explorer - The ultimate travel app';
    document.head.children.namedItem('description')['content'] =
      "Everything travel, one app. Plan trips, find things to do, discover nearby landmarks, whether you're exploring your own community or traveling the world, Explorer has you covered.";
  }

  goToApp() {
    firebase.analytics().logEvent('explorer_cta_click', { type: 'not-ios' });
    window.open('https://play.google.com/store/apps/details?id=uk.co.explorer');
  }
}
